import React, { useContext } from "react";
import { DefinitionContext } from "../../../../context/DefinitionContext";
import { HeaderContext } from "../../../../context/HeaderContext";
import { ModalContext } from "../../../../context/ModalContext";
import DefinitionModal from "../DefinitionModal/DefinitionModal";
import { CRUD_ACTIONS } from "../../../../utils/constants";

const DefinitionViewButtonList = () => {
    const {user} = useContext(HeaderContext);
    const {selected} = useContext(DefinitionContext);
    const {handleModal} = useContext(ModalContext);

    // As per requirements only trainers are allow
    if (!['admin', 'trainer'].includes(user.level)) {
        return <></>;
    }

    const canEdit = selected;

    return (
        <div className="ButtonListContainer">
            <button className="primaryButton" onClick={() => handleModal(<DefinitionModal action={CRUD_ACTIONS.CREATE} handleModal={handleModal}/>)}>
                Create
            </button>
            {canEdit && <div>
                <button onClick={() => handleModal(<DefinitionModal action={CRUD_ACTIONS.UPDATE} />)}>
                    Edit
                </button>
                <button className="dangerButton" onClick={() => handleModal(<DefinitionModal action={CRUD_ACTIONS.DELETE} handleModal={handleModal}/>)}>
                    Delete
                </button>
            </div>}
        </div>
    );
}

export default DefinitionViewButtonList;