import { useContext, useState } from "react"
import { TemplateContext } from "../../context/TemplatesContext"
import Fuse from 'fuse.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faEllipsisH, faSpinner } from "@fortawesome/free-solid-svg-icons";

/** Options to search using Fuse (fuzzy search) */
const searchOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,

};

const InteractionTemplateSelectList = () => {
    const { error, isLoading, templates, categories } = useContext(TemplateContext);
    const [search, setSearch] = useState('');

    if (error) {
        return <div style={{ paddingInline: '1rem' }}>Could not load list, please try again later.</div>;
    }

    if (isLoading) {
        return (
            <div className="flex-container column">
                <Search value={search} handleChange={setSearch} />
                <div className="grow flex-container justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
                </div>
            </div>
        );
    }

    let filteredTemplates = templates;
    let filteredCategories = categories;

    if (search.trim() !== '') {
        let fuse = new Fuse(templates, {keys: ['name', 'description', 'template'], ...searchOptions});
        filteredTemplates = fuse.search(search).map(item => item.item);

        fuse = new Fuse(categories, {keys: ['name', 'description', 'template'], ...searchOptions});
        filteredCategories = fuse.search(search).map(item => item.item);
    } else {
        filteredTemplates = templates.filter(({category_id}) => category_id === 0 );
        filteredCategories = categories.filter(({parent_id}) => parent_id === 0 );
    }

    return (
        <div className="flex-container column">
            <Search value={search} handleChange={setSearch} />
            <div className="grow height-limiter">
                <div className="template-list flex-container column">
                    {filteredCategories.map( (cat, key) => <Category key={key} category={cat} categories={categories}/> )}
                    {filteredTemplates.map( (template, key) => <Template key={key} template={template}/>)}
                </div>
            </div>
        </div>
    );
};

/** This is a very simple function component, the actual searching happens in the InteractionTemplateSelectList component */
const Search = ({value, handleChange}) => (
    <input type="text" className="templates-search" placeholder="Search ..." value={value} onChange={e => handleChange(e.target.value)}/>
);

const Template = ({template, indent = 0}) => {
    const { selected, setSelected, setSoftSelected } = useContext(TemplateContext);

    const handleOnHover = (template) => {
        if (selected) {
            return; // Don't do anything if a selection is made.
        }

        setSoftSelected(template);
    }

    return (
        <div
            className={`template-list-item template ${selected?.id === template.id ? 'active' : ''}`}
            style={{paddingLeft: `${indent}.25rem`}}
            onMouseEnter={() => handleOnHover(template)}
            onClick={() => setSelected(template)}
        >
            {template.name}
        </div>
    );
}

const Category = ({category, categories, indent = 0}) => {
    const [open, setOpen] = useState(false);
    const {templates} = useContext(TemplateContext);

    return (
        <>
            <div className="template-list-item category flex-container" style={{paddingLeft: `${indent}.25rem`}}>
                <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} size="xs" onClick={() => setOpen(!open)} />
                <div className="flex-container column grow">
                    <div className="flex-container">
                        <div className="grow" onClick={() => setOpen(!open)}>{category.name}</div>
                    </div>
                </div>
            </div>
            {open && categories.filter( ({parent_id}) => parent_id === category.id ).map((subcat, key) => <Category key={key} category={subcat} categories={categories} indent={indent+1}/>)}
            {open && templates.filter(({category_id}) => category_id === category.id).map((template, key) => <Template key={key} template={template} indent={indent+1}/>)}
        </>
    );
}

export default InteractionTemplateSelectList;
