import React, {createContext, useEffect, useState} from "react";
import { fetchAnnouncements } from "../utils/fetch";
import { useParams } from 'react-router-dom';
import { sendLog } from "../utils/utils";
import { LOG } from "../utils/constants";

/**
 * AnnouncementContext contains all announcements.
 */
const AnnouncementContext = createContext(null);

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 */
const AnnouncementContextProvider = ({ children }) => {
    const [error, setError] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [announcements,  setAnnouncements]  = useState([]);
    const [selected, setSelected] = useState(undefined);
    // A soft selected announcement is the first one in the list returned,
    // and when user hovers over a announcement.
    const [softSelected, setSoftSelected] = useState(undefined);
    const {announcementId} = useParams();

    // Load the announcements.
    useEffect(() => {
        reloadAnnouncements();
    }, []);

    const handleSetSelected = (announcement) => {
        setSelected(announcement);
        if (announcement?.id) {
            sendLog("App\\Events\\Announcement\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.ANNOUNCEMENT, LOG.DASHBOARD.ANNOUNCEMENTS, {
                id: announcement.id,
            });
        }
    }

    /** Function to reload that comes in handy on form handling. */
    const reloadAnnouncements = () => {
        setLoading(true);
        fetchAnnouncements()
            .then( announcements => {
                setAnnouncements(announcements);
                if (!selected) {
                    setSoftSelected(announcements[0]);
                    handleSetSelected(announcements.find(item => item.id === parseInt(announcementId)));
                } else {
                    setSoftSelected();
                    handleSetSelected(announcements.find(item => item.id === selected.id));
                }
            })
            .catch( error => setError(error))
            .finally( () => setLoading(false));
    };

    // Create the assets object that will be passed through.
    const isError   = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        announcements,
        setAnnouncements,
        error,
        isError,
        isLoading,
        isSuccess,
        selected,
        setSelected : handleSetSelected,
        softSelected,
        setSoftSelected,
        reloadAnnouncements,
    };

    return (
        <AnnouncementContext.Provider value={value}>
            {children}
        </AnnouncementContext.Provider>
    );
};

export { AnnouncementContext, AnnouncementContextProvider };