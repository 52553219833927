import { useContext } from "react";
import { TemplateContext } from "../../../../context/TemplatesContext";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const TemplateView = ({template: pTemplate, showTimestamp = true}) => {
    const { selected, softSelected, categories }  = useContext(TemplateContext);
    const {name, category_id, template, created_at, updated_at} = pTemplate || selected || softSelected || {};

    if (!name) {
        return <section className="TemplateView" />;
    }

    let parentId = category_id ?? 0,
        breadcrumbs = [],
        iterations = 0,
        breakLimit = 100;

    while (parentId !== 0 && iterations < breakLimit) {
        const category = categories.find( ({id}) => id === parentId);

        if(!category) {
            break;
        }

        breadcrumbs = [category.name, ...breadcrumbs];
        parentId = category.parent_id;
        iterations++;
    }

    return (
        <section className="TemplateView">
            <h1>{name}</h1>
            {breadcrumbs.length > 0 && <small>{breadcrumbs.join(' > ')}</small>}
            <div className="TemplateViewBodyContainer">
                <div className="TemplateViewBody" dangerouslySetInnerHTML={{__html: template}} />
            </div>
            {showTimestamp && (created_at || updated_at) &&
                <div>
                    {created_at !== updated_at ? 'Last updated ' : 'Created '}
                    {moment.duration(moment(updated_at).diff(moment())).humanize()} ago
                </div>
            }
        </section>
    );
};

export default TemplateView;
