import React, { Component, Fragment } from "react";
import { config } from "../../utils/config"

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
);

export default class Login extends Component {
  renderStatus() {
    switch(this.props.location.hash) {
      case "#user-inactive": {
        return (
          <Fragment>
            <p><b><em>Access denied</em></b></p>
            <p>User account is inactive.</p>
          </Fragment>
        );
      }
      default: {
        return (
          <Fragment>
            <p>You are not logged in.</p>
            <p>Please contact TASAC if this problem persists.</p>
          </Fragment>
        );
      }
    }
  }

  render() {
    const loginUrl = config.system.baseApiUrl + "/saml2/entra/login";
    return (
      <main className="errorMessage">
        <div className="errorMessageBlock">
          <div className="centered">
            <img src="/cqu-logo.png" alt="CQU Logo" />
          </div>
          <div className="errorMessageDetails">
            <h2>{config.system.name}</h2>
            {this.renderStatus()}
          </div>
          <p>
            <a href={loginUrl}>
              <button type="submit" className="primaryButton">
                <FontAwesomeIcon icon={faUser} /> Click here to login
              </button>
            </a>
          </p>
        </div>
      </main>
    );
  }
}
