import { useContext } from "react";
import { AnnouncementContext } from "../../../../context/AnnouncementContext";
import { getAnnouncementModifiedBy } from "../../../../utils/utils";

const AnnouncementView = ({announcement, showModifier = true}) => {
    const { selected, softSelected }  = useContext(AnnouncementContext);

    const {title, summary, body, author, editor, created_at, updated_at} = announcement || selected || softSelected || {};

    return (
        <section className="AnnouncementView">
            {title && <h1>{title}</h1>}
            <small>{summary}</small>
            <div className="AnnouncementViewBodyContainer">
                <div className="AnnouncementViewBody" dangerouslySetInnerHTML={{__html: body}} />
            </div>
            {showModifier &&
                <div>{getAnnouncementModifiedBy(author, editor)}</div>
            }
        </section>
    );
};

export default AnnouncementView;