import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { CRUD_ACTIONS, TINYMCE_INIT_SETTINGS } from "../../../../utils/constants";
import { DefinitionContext } from "../../../../context/DefinitionContext";
import { config } from "../../../../utils/config";
import { ModalContext } from "../../../../context/ModalContext";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import InteractionValidationErrorList from "../../../../components/InteractionForm/InteractionValidationErrorList";
import DefinitionView from "../DefinitionView/DefinitionView";
import { DefinitionCodes } from "../../../../components";

const DefinitionModal = ({action}) => {
    // Context and references.
    const { selected, reloadDefinitions, definitions } = useContext(DefinitionContext);
    const { handleModal } = useContext(ModalContext);
    const editorRef =  useRef(null);

    // States that the value of the definition.
    const [selectedCodeOption, setSelectedCodeOption] = useState('');
    const [codeOptions, setCodeOptions] = useState([]);
    const [code, setCode] = useState(selected?.code || '');
    const [content, setContent] = useState(selected?.content || '');

    // States for handling the form, and submission.
    const [errors, setErrors] = useState(null);
    const [preview, setPreview] = useState(null);
    const [sendingPreview, setSendingPreview] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect( () => {
        if (definitions) {
            const codes = definitions.map((d) => d.code);
            const options = Object.entries(DefinitionCodes).reduce((acc, [key, val]) => {
                if (! codes.includes(val)) {
                    acc.push({ value: val, label: val});
                }
                return acc;
            }, []);
            console.debug('opts', options);
            setCodeOptions(options);
        }
    }, [definitions]); // Execute only when action changes.

    // When creating a new one, reset the form because a item can be selected.
    useEffect( () => {
        if (action === CRUD_ACTIONS.CREATE) {
            setSelectedCodeOption('');
            setCode('');
            setContent('');
        }
    }, [action]); // Execute only when action changes.

    const isEditing = action === CRUD_ACTIONS.UPDATE;
    const canUpdateCode = !isEditing;

    const handleSelectedCode = (e) => {
        setSelectedCodeOption(e);
        setCode(e.value);
    }

    const handleSubmitPreview = (e) => {
        e.preventDefault();
        setSendingPreview(true);

        const url = `${config.system.baseApiUrl}/definitions-preview${isEditing ? `/${selected.id}` : ''}`;
        const data = {
            code,
            content,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setPreview(res.data.data)
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => setSendingPreview(false));
    };

    const handleSubmit = (e) => {
        const url = `${config.system.baseApiUrl}/definitions${isEditing ? `/${selected.id}` : ''}`;
        const data = {
            code,
            content,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setSubmitted(true)
                reloadDefinitions();
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => {
                setPreview();
                setSendingPreview(false);
            });
    };


    const handleGoBackButton = () => {
        setPreview();
    }

    if (action === CRUD_ACTIONS.DELETE) {
        return (
            <>
                <h3>Delete definition</h3>
                <DeleteModal />
            </>
        );
    }

    if (preview) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new definition' : 'Edit definition'} &gt; Preview</h3>
                <PreviewModal definition={preview.definition} handleGoBackButton={handleGoBackButton} handleSubmit={handleSubmit} />
            </>
        );
    }

    if (submitted) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new definition' : 'Edit definition'} &gt; Preview &gt; Submit</h3>
                <SubmittedModal />
            </>
        );
    }

    return (
        <>
            <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new definition' : 'Edit definition'}</h3>
            <form id='interactionForm' onSubmit={handleSubmitPreview}>
                <table>
                    <tbody>
                        <tr>
                            <td align="right">
                                <b>Code:</b>
                                {errors?.errors?.code && <InteractionValidationErrorList errors={errors.errors.code} />}
                            </td>
                            <td>
                                {canUpdateCode ?
                                <Select
                                    styles={{
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            zIndex: '5', // raise above tinyMCE toolbar
                                        }),
                                    }}
                                    options={codeOptions}
                                    value={selectedCodeOption}
                                    onChange={handleSelectedCode}
                                /> : <>{code}</>}
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Definition: *</b>
                                {errors?.errors?.content && <InteractionValidationErrorList errors={errors.errors.content} />}
                            </td>
                            <td>
                                <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        onEditorChange={(value,) => setContent(value)}
                                        value={content}
                                        init={TINYMCE_INIT_SETTINGS}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={'right'}>
                                {errors?.message && <InteractionValidationErrorList errors={[errors.message]} />}
                                <button type="submit" className="primaryButton" disabled={sendingPreview}>
                                    {sendingPreview ? 'Previewing ... ' : 'Preview'}
                                </button>
                                <button type="button" className="secondaryButton" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

const PreviewModal = ({ definition, handleSubmit, handleGoBackButton }) => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = () => {
        setSubmitting(true);
        handleSubmit();
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            <DefinitionView definition={definition} showModifier={false}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" className="primaryButton" onClick={onSubmit} disabled={submitting}>
                                {submitting ? 'Submitting ...' : 'Submit'}
                            </button>
                            <button type="button" className="secondaryButton" onClick={handleGoBackButton} disabled={submitting}>
                                Go Back
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

const SubmittedModal = () => {
    const { handleModal } = useContext(ModalContext);
    return (
        <div>
            <p>
                Successfully submitted the definition. You may now close this window.
            </p>
            <button type="button" className="primaryButton" onClick={() => handleModal()}>
                Close
            </button>
        </div>
    );
}

const DeleteModal = () => {
    const { selected, reloadDefinitions } = useContext(DefinitionContext);
    const { handleModal } = useContext(ModalContext);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        const url = `${config.system.baseApiUrl}/definitions/${selected.id}`;

        axios.delete(url)
            .then(res => {
                setError(null);
                handleModal();
                reloadDefinitions();
            }).catch(error => {
                setError(error.response.data.errors ?? error);
            });
    }

    if (error) {
        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {error}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            Are you sure you want to permanently delete the selected definition?
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" onClick={() => handleModal()}>
                                Cancel
                            </button>
                            <button type="button" className="dangerButton" onClick={handleDelete}>
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DefinitionModal;