import moment from 'moment-timezone';
import { useContext, useState } from 'react';
import { TemplateContext } from '../../../../context/TemplatesContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TemplateListItemTemplate from '../TemplateListItemTemplate/TemplateListItemTemplate';
import { HeaderContext } from '../../../../context/HeaderContext';
import { ModalContext } from '../../../../context/ModalContext';
import CategoryModal from '../CategoryModal/CategoryModal';
import { CRUD_ACTIONS } from '../../../../utils/constants';
moment.tz.setDefault('Australia/Brisbane');

const TemplateListItemCategory = ({category, categories, indent = 0}) => {
    const {id, name} = category;
    const [open, setOpen] = useState(false);
    const { templates } = useContext(TemplateContext);
    const { user } = useContext(HeaderContext);
    const { handleModal } = useContext(ModalContext);

    return (
        <>
            <div
                className="TemplateListItemContainer category"
                style={{paddingLeft: `${indent + 1}rem`}}
                onClick={() => setOpen(!open)}
            >
                <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} size='xs'/>
                <div className="TemplateListItemSummary">{name}</div>
                {['admin', 'trainer'].includes(user.level) &&
                    <div className='category-edit-button-container'>
                        <button
                            onClick={ () => handleModal(<CategoryModal action={CRUD_ACTIONS.UPDATE} category={category}/>)}
                        >
                            Edit
                        </button>
                        <button
                            className="dangerButton"
                            onClick={ () => handleModal(<CategoryModal action={CRUD_ACTIONS.DELETE} category={category}/>)}
                        >
                            Delete
                        </button>
                    </div>
                }
            </div>
            {open && categories.filter( ({parent_id}) => parent_id === category.id ).map((subcat, key) => <TemplateListItemCategory key={key} category={subcat} categories={categories} indent={indent+1}/>)}
            {open && templates.filter(({category_id}) => category_id === category.id).map((template, key) => <TemplateListItemTemplate key={key} template={template} indent={indent+1}/>)}
        </>
    );
};

export default TemplateListItemCategory;
