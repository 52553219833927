import React from 'react'
import Select from 'react-select'

/**
 * A disabled select input component.
 *
 * This component displays a disabled Select input with a placeholder.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} [props.placeholder="Enter placeholder ..."] - The placeholder text for the input.
 * @returns {React.Component} The DisabledSelect component.
 */
const DisabledSelect = ({ placeholder = "Enter placeholder ..." }) => {
    return (
        <Select isDisabled={true} placeholder={placeholder}  classNamePrefix="react-select" />
    );
}

export default DisabledSelect;
