import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { AimsStudentLink, IncognitoText, StudentDashboardLink } from '../../../../components';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import { HeaderContext } from '../../../../context/HeaderContext';
import useRouteParams from '../../../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const SORT_EXTENSION_DATE = ['new_due_date'];
const SORT_STUDENT_NAME = ['student.last_name', 'student.first_name'];
const SORT_STUDENT_ID = ['student.id']

const AssessmentExtensionModal = ({ assessmentName, assessmentDueDate, state, data }) => {
    const [sort, setSort] = useState(SORT_STUDENT_NAME);
    const [sortDirection, setSortDirection] = useState(true);
    const { year, term, unit } = useRouteParams();
    const { incognito } = useContext(HeaderContext);

    const title = `${state} Extensions for:  ${assessmentName}`;

    const sortTable = (sortBy) => {
        if (sortBy === sort) {
            setSortDirection(!sortDirection);
            return;
        }

        setSort(sortBy);
        setSortDirection(true);
    };

    const extensions = sortDirection ? sortBy(data, sort) : sortBy(data, sort).reverse();

    return (
        <>
            <div style={{ textAlign: "center" }}>
                <h3>{title}</h3>
            </div>
            <table className="dataTable" style={{ margin: "0px auto" }}>
                <tbody>
                    <tr>
                        <th  onClick={() => sortTable(SORT_STUDENT_ID)}>
                            <FontAwesomeIcon icon={sort === SORT_STUDENT_ID ? sortDirection ? faSortDown : faSortUp : faSort} /> Student ID
                        </th>
                        <th onClick={() => sortTable(SORT_STUDENT_NAME)}>
                            <FontAwesomeIcon icon={sort === SORT_STUDENT_NAME ? sortDirection ? faSortDown : faSortUp : faSort} /> Student Name
                        </th>
                        <th>Outcome</th>
                        <th>Original due date</th>
                        <th>Extension</th>
                        <th onClick={() => sortTable(SORT_EXTENSION_DATE)}>
                            <FontAwesomeIcon icon={sort === SORT_EXTENSION_DATE ? sortDirection ? faSortDown : faSortUp : faSort} /> Extension due date
                        </th>
                    </tr>
                    {extensions.map((elem, key) =>
                        <tr key={key}>
                            <td>
                                <AimsStudentLink studentCode={elem.student.code}>
                                    <IncognitoText text={elem.student.id} incognito={incognito} />
                                </AimsStudentLink>
                            </td>
                            <td>
                                <StudentDashboardLink studentId={elem.student.id} term={{year, term}} unitCode={unit}>
                                    <IncognitoText text={`${elem.student.last_name}, ${elem.student.first_name}`} incognito={incognito} />
                                </StudentDashboardLink>
                            </td>
                            <td>{state}</td>
                            <td>{assessmentDueDate}</td>
                            <td>{elem.new_due_date && moment.duration(moment(elem.new_due_date).diff(assessmentDueDate)).humanize()}</td>
                            <td>{elem.new_due_date}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default AssessmentExtensionModal;