import React from 'react'
import Select from 'react-select'
import useSchools from '../../../hooks/useSchools';
import { convertAssetsToOptions } from '../../../utils/utils';

const SchoolSelect = ({ enabled, year, term, value, onChange }) => {
    const {data, isLoading, isError, isSuccess} = useSchools(year, term);

    return (
        <Select
            isMulti
            isDisabled={!enabled || isLoading || isError || data.length === 0}
            isLoading={isLoading}
            options={ isSuccess ? convertAssetsToOptions(data) : []}
            value={value}
            placeholder="School ..."
            onChange={onChange}
            classNamePrefix="react-select"
        />
    );
}

export default SchoolSelect;
