import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { PrivateRoute } from "./components";
import { CookiesProvider } from "react-cookie";
import {
  Announcements,
  Definitions,
  Home,
  UnitDashboard,
  Error,
  Login,
  SiteConfig,
  SiteDiagnostics,
  SuccessStrategies,
  StudentDashboard,
  Templates,
} from './pages';
import { config } from "./utils/config";

import "./assets/App.css";
import CohortDashboard from "./pages/CohortDashboard/CohortDashboard";

const Error404 = () => (
  <Error
    error={{
      code: 404,
      title: "Page not found",
      message: "The URL provided is incorrect.",
    }}
  />
);

const App = () => (
  <CookiesProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/login/:code?" component={Login} />
        <Route path="/login-redirect" component={() => {
          window.location.href = config.system.baseApiUrl + "/saml2/entra/login";
          return null;
        }} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/announcements/:announcementId" component={Announcements} />
        <PrivateRoute path="/announcements" component={Announcements} />
        <PrivateRoute path="/definitions" component={Definitions} />
        <PrivateRoute path="/templates" component={Templates} />
        <PrivateRoute path="/cohort/:year/:term" component={CohortDashboard} />
        <PrivateRoute path="/student/:year/:term/:student/:unit" component={StudentDashboard} />
        <PrivateRoute path="/unit/:year/:term/:unit" component={UnitDashboard} />
        <PrivateRoute path="/config" component={SiteConfig} />
        <PrivateRoute path="/success-strategies" component={SuccessStrategies} />
        <PrivateRoute path="/diagnostics" component={SiteDiagnostics} />
        <PrivateRoute component={Error404} />
      </Switch>
    </BrowserRouter>
  </CookiesProvider>
);

export default App;
