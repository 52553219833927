import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { ModalContext } from '../../../../context/ModalContext';
import { color_scheme, getTypeIcon } from '../../../../utils/utils';
import { config } from '../../../../utils/config';
import { InteractionForm } from '../../../../components';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

const InteractionTableTitle = ({children, strategies}) => {
    const { handleModal } = useContext(ModalContext);
    const colors = color_scheme(config.notes, config.scheme.interactions);
    const {selectedEnrollments} = useContext(SelectedEnrollmentsContext);

    return (
        <>
            <p className="buttonRow" style={{float: 'right'}}>
                <button disabled={selectedEnrollments.size === 0} onClick={() => handleModal(
                        <InteractionForm type='Email' fullCohort={false} strategies={strategies} />
                )}>
                    <FontAwesomeIcon icon={getTypeIcon('Email')} style={{color: colors['Email']}}/> New Email</button>
                <button disabled={selectedEnrollments.size === 0} onClick={() => handleModal(
                        <InteractionForm type='Note' fullCohort={false} strategies={strategies} />
                )}>
                    <FontAwesomeIcon icon={getTypeIcon('Note')} style={{color: colors['Note']}}/> New Note
                </button>
            </p>
            <h2>{children}</h2>
        </>
    );
}

export default InteractionTableTitle;

