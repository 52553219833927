import { useContext } from "react";
import { DefinitionContext } from "../../../../context/DefinitionContext";

const DefinitionView = ({definition}) => {
    const { selected, softSelected }  = useContext(DefinitionContext);

    const {code, content, created_at, updated_at} = definition || selected || softSelected || {};

    return (
        <section className="DefinitionView">
            <h1>{code}</h1>
            <div className="DefinitionViewBodyContainer">
                <div className="DefinitionViewBody" dangerouslySetInnerHTML={{__html: content}} />
            </div>
        </section>
    );
};

export default DefinitionView;