import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select'
import { TemplateContext } from "../../../../context/TemplatesContext";
import { ModalContext } from "../../../../context/ModalContext";
import { CRUD_ACTIONS } from "../../../../utils/constants";
import { config } from "../../../../utils/config";
import InteractionValidationErrorList from "../../../../components/InteractionForm/InteractionValidationErrorList";
import { findValidParents } from "../../../../utils/utils";

const CategoryModal = ({action, category: selected}) => {
    // Content and references.
    const { reloadTemplates, categories } = useContext(TemplateContext);
    const { handleModal } = useContext(ModalContext);

    // States of template fields.
    const [name, setName] = useState(selected?.name || '');
    const [parentId, setParentId] = useState(selected?.parent_id || 0);

    // States for handling the form, and submission.
    const [errors, setErrors] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // When creating a new one, reset the form because a item can be selected.
    useEffect( () => {
        if (action === CRUD_ACTIONS.CREATE) {
            setName('');
            setParentId(0);
        }
    }, [action]); // Execute only when action changes.

    const isEditing = action === CRUD_ACTIONS.UPDATE;

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        const url = `${config.system.baseApiUrl}/template-categories${isEditing ? `/${selected.id}` : ''}`;
        const data = {
            name,
            parent_id: parentId,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setSubmitted(true)
                reloadTemplates();
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally( () => {
                setSubmitting(false);
            });
    };

    if (action === CRUD_ACTIONS.DELETE) {
        return (
            <>
                <h3>Delete category "{selected.name}" and templates</h3>
                <DeleteModal category={selected} />
            </>
        );
    }

    if (submitted) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new category' : 'Edit category'} &gt; Submit</h3>
                <SubmittedModal />
            </>
        );
    }

    const validParents = !selected ? categories : findValidParents(categories, selected.id);
    const categoryOptions = validParents.map( ({id, name}) => {
        return { value: id, label: name }
    });
    categoryOptions.unshift({value: 0, label: 'Default'});

    return (
        <>
            <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new category' : 'Edit category'}</h3>
            <form id='interactionForm' onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td align="right">
                                <b>Parent category: *</b>
                            </td>
                            <td>
                                <Select
                                    isDisabled={categories.length === 0}
                                    options={categoryOptions}
                                    defaultValue={categoryOptions.find( ({value}) => value === parentId )}
                                    onChange={({value}) => setParentId(value)}
                                    className="react-select"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Name: *</b>
                                {errors?.errors?.name && <InteractionValidationErrorList errors={errors.errors.name} />}
                            </td>
                            <td>
                                <input type="text" name="name" onChange={(e) => setName(e.target.value)} value={name}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={'right'}>
                                {errors?.message && <InteractionValidationErrorList errors={[errors.message]} />}
                                <button type="submit" className="primaryButton" disabled={submitting} onClick={handleSubmit}>
                                    {submitting ? 'Submitting ...' : 'Submit'}
                                </button>
                                <button type="button" className="secondaryButton" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
};

const SubmittedModal = () => {
    const { handleModal } = useContext(ModalContext);
    return (
        <div>
            <p>
                Successfully submitted the category. You may now close this window.
            </p>
            <button type="button" className="primaryButton" onClick={() => handleModal()}>
                Close
            </button>
        </div>
    );
}

const DeleteModal = ({category: selected}) => {
    const { reloadTemplates } = useContext(TemplateContext);
    const { handleModal } = useContext(ModalContext);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        const url = `${config.system.baseApiUrl}/template-categories/${selected.id}`;

        axios.delete(url)
            .then(res => {
                setError(null);
                handleModal();
                reloadTemplates();
            }).catch(error => {
                setError(error.response.data.errors ?? error);
            });
    }

    if (error) {
        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {error}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            Are you sure you want to permanently delete the category and it's templates?
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" onClick={() => handleModal()}>
                                Cancel
                            </button>
                            <button type="button" className="dangerButton" onClick={handleDelete}>
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CategoryModal;
