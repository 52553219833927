import { faMinusCircle, faPlusCircle, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Header, Spinner } from "../../components";
import { config as SiteConfig } from "../../utils/config";
import { COUNT_SHOW_DEFAULT, COUNT_SHOW_DELAY, COUNT_SHOW_ERROR, COUNT_SHOW_LOADING, LOG } from "../../utils/constants";
import { getParams, sendLog } from "../../utils/utils";
import {
    CampusSelect,
    CourseSelect,
    CollegeSelect,
    DashboardButton,
    MAPSelect,
    MatchesButton,
    SchoolSelect,
    TermSelect,
    UnitSelect,
    StudentSelect,
    FlagSelect,
    RangeSelect,
    DisabledSelect
} from "./components";
import Error from "../Error/Error";
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { getUserCourseServices } from '../../utils/utils';

const Home = ({ incognito }) => {
    return (
        <HeaderContextProvider incognito={incognito}>
            <Main/>
        </HeaderContextProvider>
    );
}

const Main = () => {
    const [count, setCount] = useState(COUNT_SHOW_DEFAULT);
    const [countLoading, setCountLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);

    // The form elements. sel is a abbreviation for selected.
    const [selUnits, setSelUnits] = useState([]);
    const [selCourses, setSelCourses] = useState([]);
    const [selStudents, setSelStudents] = useState([]);
    const [selCampuses, setSelCampuses] = useState([]);
    const [selColleges, setSelColleges] = useState([]);
    const [selSchools, setSelSchools] = useState([]);
    const [selMAPs, setSelMAPs] = useState([]);
    const [selFlags, setSelFlags] = useState([]);
    const [selAges, setSelAges] = useState();
    const [selLastLogin, setSelLastLogin] = useState();
    const [selEnrolled, setSelEnrolled] = useState();

    const {
        config,
        user,
        incognito: isIncognito,
        setIncognito,
        imports,
        notices,
        services,
        setServices,
        calendar,
        selTerm,
        setSelTerm,
        terms,
        error,
        isLoading,
        isSuccess,
    } = useContext(HeaderContext);

    /**
     *  On page load.
     */
    useEffect(() => {
        if (isSuccess) {
            const services = getUserCourseServices(user, terms);
            setServices(services.slice(0, 10));

            sendLog('App\\Events\\Home\\Viewed', 'r', LOG.ACTION.VIEW, LOG.TARGET.PAGE, LOG.DASHBOARD.HOME);
        }
    }, [isSuccess]);

    /**
     * This runs when the input changes, and loads the count.
     */
    useEffect(() => {
        const params = getParams(selUnits, selCourses, selStudents, selCampuses, selColleges, selSchools, selMAPs, selFlags, selAges, selLastLogin, selEnrolled);
        if (!selTerm || params === '') {
            setCount(COUNT_SHOW_DEFAULT);
            setCountLoading(false);
            return;
        }

        setCount(COUNT_SHOW_LOADING);
        setCountLoading(true);

        // Use a slight delay before calculating executing.
        const timeOutId = setTimeout(() => {
            axios.get(SiteConfig.system.baseApiUrl + `/terms/${selTerm.year}/${selTerm.term}/enrollments?count${params}`)
                .then(res => {
                    setCount(res.data);
                    setCountLoading(false);
                    sendLog('App\\Events\\Home\\Filtered', 'r', LOG.ACTION.FILTER, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.HOME, {
                        term: { year: selTerm.year, term: selTerm.term },
                        path: params.replace('&path=', '').split(','),
                        total: res.data
                    });
                })
                .catch(error => {
                    console.log(error);
                    setCount(COUNT_SHOW_ERROR);
                    setCountLoading(false);
                });
        }, COUNT_SHOW_DELAY);

        return () => clearTimeout(timeOutId);
    }, [selTerm, selUnits, selCourses, selStudents, selCampuses, selColleges, selSchools, selMAPs, selFlags, selAges, selLastLogin, selEnrolled]); // When any of these change.

    // Stop here if the data is still loading or has errored.
    if (error) {
        return <Error error={error} />;
    }
    if (isLoading) {
        return <Spinner />;
    }

    /**
     *  When someone changes term, all selections should be reset.
     */
    const resetSelections = () => {
        setSelUnits([]);
        setSelCourses([]);
        setSelStudents([]);
        setSelCampuses([]);
        setSelColleges([]);
        setSelSchools([]);
        setSelMAPs([]);
        setSelFlags([]);
        setSelAges('');
        setSelLastLogin('');
        setSelEnrolled('');
    };

    /**
     *  Handle selection of term. If the term changes reset the other selections.
     */
    const handleTermChange = ({ value }) => {
        setSelTerm(terms.find(element => element.id === value));
        resetSelections();
    };

    // Return the page.
    return (
        <>
            <Header
                config={config}
                calendar={calendar}
                imports={imports}
                user={user}
                notices={notices}
                services={services}
                incognito={isIncognito}
                handleUpdateIncognito={setIncognito}
            />
            <main className="homePage">
                <nav className="centerPanel">
                        <TermSelect terms={terms} value={selTerm.id} onChange={handleTermChange} />
                        <UnitSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selUnits} onChange={setSelUnits} />
                        <CampusSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selCampuses} onChange={setSelCampuses} />
                        <StudentSelect enabled={!!selTerm} incognito={isIncognito} year={selTerm.year} term={selTerm.term} value={selStudents} onChange={setSelStudents} />
                        <CourseSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selCourses} onChange={setSelCourses} />
                        <CollegeSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selColleges} onChange={setSelColleges} />
                    {showMore && <>
                            <SchoolSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selSchools} onChange={setSelSchools} />
                            {selTerm?.term !== 'VET'
                                ? <MAPSelect enabled={!!selTerm} year={selTerm.year} term={selTerm.term} value={selMAPs} onChange={setSelMAPs} />
                                : <DisabledSelect placeholder="MAPs ..." />
                            }
                            <FlagSelect enabled={!!selTerm} value={selFlags} onChange={setSelFlags} />
                            <RangeSelect enabled={!!selTerm} placeholder={'Age (range)'} value={selAges} onChange={setSelAges} />
                            <RangeSelect enabled={!!selTerm} placeholder={'Days Since Login (range)'} value={selLastLogin} onChange={setSelLastLogin} />
                            <RangeSelect enabled={!!selTerm} placeholder={'Days Since Enrolled (range)'} value={selEnrolled} onChange={setSelEnrolled} />
                    </>}
                    <div className="showMore" onClick={() => setShowMore(!showMore)}>
                        <FontAwesomeIcon icon={showMore ? faMinusCircle : faPlusCircle} /> {showMore ? 'Fewer' : 'More'} Filter Options
                    </div>
                </nav>
                <nav className="buttonPanel">

                    <DashboardButton selTerm={selTerm} user={user} units={selUnits} courses={[]} colleges={[]} />
                    <MatchesButton loading={countLoading} count={count} selTerm={selTerm}

                        params={getParams(selUnits, selCourses, selStudents, selCampuses, selColleges, selSchools, selMAPs, selFlags, selAges, selLastLogin, selEnrolled)}
                    />
                </nav>
            </main>
        </>
    );
}

export default Home;
