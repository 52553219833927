import React, { useContext } from "react";
import { AssetsContext } from "../../context/AssetsContext";
import { ModalContext } from '../../context/ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { sendLog } from "../../utils/utils";
import { LOG } from "../../utils/constants";

const DefinitionInfo = (props) => {
    const { code } = props;
    const assets = useContext(AssetsContext);
    const { handleModal } = useContext(ModalContext);

    if (!assets || !assets.isSuccess || !assets.definitions) {
        return <></>;
    }

    const definition = assets.definitions[code];

    const handleClick = (e) => {
        e.stopPropagation();
        handleModal(<div><h1>{definition.code}</h1><div dangerouslySetInnerHTML={{__html: definition.content}} /></div>);
        sendLog("App\\Events\\Definition\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.DEFINITION, LOG.DASHBOARD.COHORT, {
            code: definition.code,
        });
    }

    return definition ? (
        <FontAwesomeIcon icon={faInfoCircle} className='definitionIcon' onClick={handleClick}/>
    ) : <></>;
}

export default DefinitionInfo;
