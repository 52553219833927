import { useContext } from "react"
import { TemplateContext } from "../../context/TemplatesContext"

const InteractionTemplateSelectPreview = ({template}) => {
    const {selected, softSelected} = useContext(TemplateContext);

    const temp = template || selected || softSelected || {};

    return (
        <div className="grow height-limiter bordered" style={{overflowY: 'auto'}}>
            <div style={{paddingInline: '1rem' }} dangerouslySetInnerHTML={{__html: temp.template}} />
        </div>
    );
}

export default InteractionTemplateSelectPreview;
