import { useContext, useState } from "react";
import {DefinitionListItem, DefinitionListSearch} from "../../components";
import { DefinitionContext } from "../../../../context/DefinitionContext";
import { Spinner } from "../../../../components";
import Fuse from 'fuse.js';

/** Options to search using Fuse (fuzzy search) */
const options = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['code', 'content']
};

const DefinitionList = () => {
    const { error, isLoading, definitions } = useContext(DefinitionContext);
    const [search, setSearch] = useState('');
    const [resultLimit, setResultLimit] = useState(25); // State for result limit

    if (error) {
        return <div style={{ paddingInline: '1rem' }}>Could not load list, please try again later.</div>;
    }

    if (isLoading) {
        return <Spinner text={'Loading definitions ... '} />;
    }

    const fuse = new Fuse(definitions, options);
    let filtered = definitions;

    if (search !== '') {
        filtered = fuse.search(search).map(item => item.item);
    }

    const handleShowMore = () => {
        setResultLimit(resultLimit + 25); // Increase limit by 25
    };

    return (
        <>
            <DefinitionListSearch value={search} handleChange={setSearch} />
            <div className="DefinitionListContainerHeightLimiter">
                <div className="DefinitionListContainer">
                    {filtered.slice(0, resultLimit).map((item, index) => (
                        <DefinitionListItem key={index} definition={item} />
                    ))}
                    {filtered.length > resultLimit && ( // Check if there are more results
                        <button className='padding' onClick={handleShowMore}>Show more</button>
                    )}
                </div>
            </div>
        </>
    );
};

export default DefinitionList;