import React from 'react';
import Select from "react-select";
import { CURRENT_VET_YEAR_LABEL, CURRENT_VET_YEAR_VALUE } from '../../../utils/constants';

const TermSelect = ({terms, value, onChange}) => {
    const options = terms.map( (term) => {
        return {
            value: term.id,
            label: `${term.year === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_LABEL : term.year}.${term.term}`
        }
    });

    return (
        <Select
            options={options}
            defaultValue={options.find( element => element.value === value )}
            isClearable={false}
            placeholder="Select Term"
            onChange={onChange}
            classNamePrefix="react-select"
        />
    );
}

export default TermSelect;
