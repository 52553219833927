import { useContext, useState } from "react";
import { TemplateListItemTemplate, TemplateListItemCategory, TemplateListSearch } from "../../components";
import { TemplateContext } from "../../../../context/TemplatesContext";
import { Spinner } from "../../../../components";
import Fuse from 'fuse.js';

/** Options to search using Fuse (fuzzy search) */
const searchOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
};

const TemplateList = () => {
    const {error, isLoading, templates, categories } = useContext(TemplateContext);
    const [search, setSearch] = useState('');

    if (error) {
        return <div style={{ paddingInline: '1rem' }}>Could not load list, please try again later.</div>;
    }

    if (isLoading) {
        return <Spinner text={'Loading template ... '} />;
    }

    let filteredTemplates = templates;
    let filteredCategories = categories;

    if (search.trim() !== '') {
        let fuse = new Fuse(templates, {keys: ['name', 'description', 'template'], ...searchOptions});
        filteredTemplates = fuse.search(search).map(item => item.item);

        fuse = new Fuse(categories, {keys: ['name', 'description', 'template'], ...searchOptions});
        filteredCategories = fuse.search(search).map(item => item.item);
    } else {
        filteredTemplates = templates.filter(({category_id}) => category_id === 0 );
        filteredCategories = categories.filter(({parent_id}) => parent_id === 0 );
    }

    return (
        <>
            <TemplateListSearch value={search} handleChange={setSearch} />
            <div className="TemplateListContainerHeightLimiter">
                <div className="TemplateListContainer">
                    {filteredCategories.map( (cat, index) => <TemplateListItemCategory key={index} category={cat} categories={categories}/> )}
                    {filteredTemplates.map( (template, index) => <TemplateListItemTemplate key={index} template={template}/>)}
                </div>
            </div>
        </>
    );
};

export default TemplateList;
