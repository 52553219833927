import React, { useContext } from "react";
import Error from "../Error/Error";
import { Header, Spinner } from "../../components";
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { ModalProvider } from "../../context/ModalContext";
import { TemplateContextProvider } from "../../context/TemplatesContext";
import { TemplateList, TemplateView, TemplateViewButtonList } from "./components";

const Home = ({ incognito }) => {
    return (
        <HeaderContextProvider incognito={incognito}>
            <TemplateContextProvider>
                <ModalProvider>
                    <Main/>
                </ModalProvider>
            </TemplateContextProvider>
        </HeaderContextProvider>
    );
}

const Main = () => {
    const {
        config,
        user,
        incognito: isIncognito,
        setIncognito,
        imports,
        notices,
        services,
        calendar,
        error,
        isLoading,
    } = useContext(HeaderContext);

    // Stop here if the data is still loading or has errored.
    if (error) {
        return <Error error={error} />;
    }
    if (isLoading) {
        return <Spinner />;
    }

    // Return the page.
    return (
        <>
            <Header
                config={config}
                calendar={calendar}
                imports={imports}
                user={user}
                notices={notices}
                services={services}
                incognito={isIncognito}
                handleUpdateIncognito={setIncognito}
                breadcrumbs={[['Templates']]}
            />
            <main className="TemplatesPage">
                <section>
                    <h4>Templates</h4>
                    <TemplateList />
                </section>
                <div className="TemplateViewPanel">
                    <TemplateViewButtonList />
                    <TemplateView />
                </div>
            </main>
        </>
    );
}

export default Home;
