import React, { useContext } from "react";
import Error from "../Error/Error";
import { Header, Spinner } from "../../components";
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { AnnouncementContextProvider } from "../../context/AnnouncementContext";
import { ModalProvider } from "../../context/ModalContext";
import { AnnouncementList, AnnouncementView, AnnouncementViewButtonList } from "./components";

const Home = ({ incognito }) => {
    return (
        <HeaderContextProvider incognito={incognito}>
            <AnnouncementContextProvider>
                <ModalProvider>
                    <Main/>
                </ModalProvider>
            </AnnouncementContextProvider>
        </HeaderContextProvider>
    );
}

const Main = () => {
    const {
        config,
        user,
        incognito: isIncognito,
        setIncognito,
        imports,
        notices,
        services,
        calendar,
        error,
        isLoading,
    } = useContext(HeaderContext);

    // Stop here if the data is still loading or has errored.
    if (error) {
        return <Error error={error} />;
    }
    if (isLoading) {
        return <Spinner />;
    }

    // Return the page.
    return (
        <>
            <Header
                config={config}
                calendar={calendar}
                imports={imports}
                user={user}
                notices={notices}
                services={services}
                incognito={isIncognito}
                handleUpdateIncognito={setIncognito}
                breadcrumbs={[['Announcements']]}
            />
            <main className="AnnouncementsPage">
                <section>
                    <h4>Announcements</h4>
                    <AnnouncementList/>
                </section>
                <div className="AnnouncementViewPanel">
                    <AnnouncementViewButtonList />
                    <AnnouncementView />
                </div>
            </main>
        </>
    );
}

export default Home;
