import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as ICON_MAIL, faStickyNote as ICON_NOTE, faCube as ICON_CRM, faComment as ICON_MY_EXPERIENCE } from '@fortawesome/free-solid-svg-icons';
import { ModalContext } from '../../../context/ModalContext';
import { CrmCasesList, InteractionList, MyExperienceList } from './index';
import { LOG } from '../../../utils/constants';
import { replaceCreationUser, sendLog } from '../../../utils/utils';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import useRouteParams from '../../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const InteractionCell = ({ studentId, unitCode, interactionsSent, interactionCases, myExperiences }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term } = useRouteParams();
    const interactions = [
        { icon: ICON_MAIL, title: "Emails", list: interactionsSent.filter(ia => ['Mailout', 'Email'].includes(ia.interaction.interaction_type)), },
        { icon: ICON_NOTE, title: "Notes", list: interactionsSent.filter(ia => ['Note'].includes(ia.interaction.interaction_type)), },
        { icon: ICON_CRM, title: "CRM Notes", list: interactionCases },
        { icon: ICON_MY_EXPERIENCE, title: "Feedback", list: myExperiences, },
    ];


    return (
        <td className='popoverContainer'>{interactions.map((ints, index) => (ints.list.length === 0 ?
            <div key={index} className='leftQuarter interactionIcon'>
                <FontAwesomeIcon icon={ints.icon} style={{ visibility: 'hidden' }} />
            </div>
            :
            <div key={index}
                title={ints.title}
                className='leftQuarter interactionIcon popoverParent'
                onClick={() => {
                    let name, target, ids;
                    if (ints.icon === ICON_MY_EXPERIENCE) {
                        handleModal( <MyExperienceList items={ints.list} />);
                        name = 'App\\Events\\MyExperience\\Viewed';
                        target = LOG.TARGET.MY_EXPERIENCE;
                        ids = ints.list.map( int  => int.response_id);
                    } else if (ints.icon === ICON_CRM) {
                        handleModal( <CrmCasesList items={ints.list} />);
                        name = 'App\\Events\\Interaction\\Viewed';
                        target = LOG.TARGET.CRM_CASES;
                        ids = ints.list.map( int  => int.case_number);
                    } else {
                        handleModal( <InteractionList items={ints.list} />);
                        name = 'App\\Events\\Interaction\\Viewed';
                        target = LOG.TARGET.INTERACTION;
                        ids = ints.list.map( int  => int.interaction_id);
                    }
                    sendLog(name, 'r', LOG.ACTION.VIEW, target, LOG.DASHBOARD.COHORT, {
                        term: { year, term },
                        enrollment : {studentId, unitCode},
                        ids
                    });
                }}
            >
                <div className='badge'>{ints.list.length}</div>
                <FontAwesomeIcon icon={ints.icon} style={{ cursor: 'pointer' }} />
                <div className='popoverChild'>{ints.icon === ICON_MY_EXPERIENCE ?
                    sortBy(ints.list, ['recorded_date']).reverse().map((i, index) => (
                        <div key={index}>
                            <div className="popoverHeading">Feedback, {moment(i.recorded_date, 'YYYY-MM-DD hh:ii:ss').format('D MMM')}</div>
                            <div className="popoverBody">Satisfaction score: {i.rating}</div>
                        </div>
                    ))
                    :
                    ints.icon === ICON_CRM ?
                    sortBy(ints.list, ['creation_date']).reverse().map((i, index) => (
                        <div key={index}>
                            <div className="popoverHeading">CRM, {moment(i.creation_date, 'YYYY-MM-DD hh:ii:ss').format('D MMM')}, {i.assigned_team}</div>
                            <div className="popoverBody">{i.case_type}</div>
                        </div>
                    ))
                    :
                    sortBy(ints.list, ['time_created']).reverse().map((i, index) => (
                        <div key={index}>
                            <div className="popoverHeading">{i.interaction.interaction_type}, {moment(i.time_created, 'YYYY-MM-DD hh:ii:ss').format('D MMM')}, {i.interaction.username}</div>
                            <div className="popoverBody">{i.interaction.strategy_name}</div>
                        </div>
                    ))
                }
                </div>
            </div>
        ))}
        </td>
    );
}

export default InteractionCell;