import React from 'react';

const RangeSelect = ({enabled, value, onChange, placeholder}) => {

    const handleChange = (event) => {
        // Only allows, numbers, commas and dashes.
        const result = event.target.value
            .toLowerCase()           // To lowercase.
            .replace(/[^0-9,-]/gi, '');

        onChange(result);
    }

    return (
        <input className="numberRange" placeholder={placeholder} disabled={!enabled} value={value} onChange={handleChange}/>
    );
}

export default RangeSelect;