import { useContext } from "react";
import moment from 'moment-timezone';
import { TemplateContext } from "../../../../context/TemplatesContext";
moment.tz.setDefault('Australia/Brisbane');

const TemplateListItemTemplate = ({template, indent = 0}) => {
    const {id, name, created_at, updated_at} = template;
    const {selected, setSelected, setSoftSelected} = useContext(TemplateContext);
    const active = selected && selected.id == id ? true : false;

    const handleOnHover = (template) => {
        if (selected) {
            return; // Don't do anything if a selection is made.
        }

        setSoftSelected(template);
    }

    return (
        <div
            className={`TemplateListItemContainer ${active ? 'active' : ''}`}
            style={{paddingLeft: `${indent + 1}rem`}}
            onMouseEnter={() => handleOnHover(template)}
            onClick={() => setSelected(template)}
        >
            <div className="TemplateListItemSummary">{name}</div>
            <div className="TemplateListItemDetails">
                <div className="updated">
                    {created_at !== updated_at ? 'Last updated ' : 'Created '}
                    {moment.duration(moment(updated_at).diff(moment())).humanize()} ago
                </div>
            </div>
        </div>
    );
};

export default TemplateListItemTemplate;
