import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { config } from '../../../utils/config';
import { convertAssetsToOptions } from '../../../utils/utils';

const UnitSelect = ({ enabled, year, term, value, onChange }) => {
    const [isLoading, setLoading] = useState(true);
    const [defaultOptions, setDefaultOptions] = useState([]);

    /**
     * Loads the default options.
     */
    useEffect(() => {
        setLoading(true)
        axios.get(config.system.baseApiUrl + `/terms/${year}/${term}/units`)
            .then(res => {
                const options = convertAssetsToOptions(res.data.data);
                setDefaultOptions(options);
                setLoading(false);
            });
    }, [year, term]); // Only re-run the effect if year or term changes.

    /**
     * Method to load the option asynchronously.
     *
     * @param {string} input
     * @param {callback} callback
     * @returns
     */
    const loadOptions = (input, callback) => {
        // Must be longer then 3.
        if (input.length < 3) {
            return callback([]);
        }

        axios.get(config.system.baseApiUrl + `/terms/${year}/${term}/units?limit=100&search=${input.toUpperCase()}`)
            .then(res => {
                const options = convertAssetsToOptions(res.data.data);
                return callback(options);
            })
            .catch(error => {
                console.log(error);
                return callback([]);
            })
    }

    return (
        <AsyncSelect
            isMulti
            isDisabled={!enabled || isLoading}
            isLoading={isLoading}
            loadOptions={loadOptions}
            value={value}
            placeholder="Units ..."
            onChange={onChange}
            classNamePrefix="react-select"
            defaultOptions={defaultOptions}
        />
    );
}

export default UnitSelect;
