import { useContext, useEffect, useRef, useState } from "react";
import axios from 'axios';
import Select from 'react-select'
import moment from "moment-timezone";
import { TemplateContext } from "../../../../context/TemplatesContext";
import { ModalContext } from "../../../../context/ModalContext";
import { CRUD_ACTIONS, TINYMCE_INIT_SETTINGS } from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../../utils/config";
import { Editor } from "@tinymce/tinymce-react";
import InteractionValidationErrorList from "../../../../components/InteractionForm/InteractionValidationErrorList";
import TemplateView from "../TemplateView/TemplateView";
moment.tz.setDefault('Australia/Brisbane');

const TemplateModal = ({action}) => {
    // Content and references.
    const { selected, reloadTemplates, setSelected, categories } = useContext(TemplateContext);
    const { handleModal } = useContext(ModalContext);
    const editorRef = useRef(null);

    // States of template fields.
    const [name, setName] = useState(selected?.name || '');
    const [template, setTemplate] = useState(selected?.template || '');
    const [categoryId, setCategoryId] = useState(selected?.category_id || 0);

    // States for handling the form, and submission.
    const [errors, setErrors] = useState(null);
    const [preview, setPreview] = useState(null);
    const [sendingPreview, setSendingPreview] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // When creating a new one, reset the form because a item can be selected.
    useEffect( () => {
        if (action === CRUD_ACTIONS.CREATE) {
            setName('');
            setCategoryId(0);
            setTemplate('');
        }
    }, [action]); // Execute only when action changes.

    const isEditing = action === CRUD_ACTIONS.UPDATE;

    const handleSubmitPreview = (e) => {
        e.preventDefault();
        setSendingPreview(true);

        const url = `${config.system.baseApiUrl}/templates-preview`;
        const data = {
            category_id: categoryId,
            name,
            template,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setPreview(res.data.data)
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => setSendingPreview(false));
    };

    const handleSubmit = (e) => {
        const url = `${config.system.baseApiUrl}/templates${isEditing ? `/${selected.id}` : ''}`;
        const data = {
            category_id: categoryId,
            name,
            template,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setSubmitted(true)
                reloadTemplates();
                setSelected(res.data.data.template);
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => {
                setPreview();
                setSendingPreview(false);
            });
    };

    const handleGoBackButton = () => {
        setPreview();
    }

    const handleTextInsert = (textToInsert) => {
        editorRef.current.insertContent(textToInsert);
    }

    if (action === CRUD_ACTIONS.DELETE) {
        return (
            <>
                <h3>Delete template "{selected.name}"</h3>
                <DeleteModal />
            </>
        );
    }

    if (preview) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new template' : 'Edit template'} &gt; Preview</h3>
                <PreviewModal template={preview.template} handleGoBackButton={handleGoBackButton} handleSubmit={handleSubmit} />
            </>
        );
    }

    if (submitted) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new template' : 'Edit template'} &gt; Preview &gt; Submit</h3>
                <SubmittedModal />
            </>
        );
    }

    const categoryOptions =  categories.map( ({id, name}) => {
        return { value: id, label: name }
    });
    categoryOptions.unshift({value: 0, label: 'Default'});

    return (
        <>
            <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new template' : 'Edit template'}</h3>
            <form id='interactionForm' onSubmit={handleSubmitPreview}>
                <table>
                    <tbody>
                        <tr>
                            <td align="right">
                                <b>Category: *</b>
                            </td>
                            <td>
                                <Select
                                    isDisabled={categories.length === 0}
                                    options={categoryOptions}
                                    defaultValue={categoryOptions.find( ({value}) => value === categoryId )}
                                    onChange={({value}) => setCategoryId(value)}
                                    className="react-select"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Name: *</b>
                                {errors?.errors?.name && <InteractionValidationErrorList errors={errors.errors.name} />}
                            </td>
                            <td>
                                <input type="text" name="name" onChange={(e) => setName(e.target.value)} value={name}/>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Template: *</b>
                                {errors?.errors?.template && <InteractionValidationErrorList errors={errors.errors.template} />}
                                <div className="vertical-space">
                                    {config.interactions.fieldNames.VET.map((name, index) => (
                                        <div key={index} className="textInsert" onClick={() => handleTextInsert('{' + name + '}')}>
                                            {name} <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        onEditorChange={(value,) => setTemplate(value)}
                                        value={template}
                                        init={TINYMCE_INIT_SETTINGS}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={'right'}>
                                {errors?.message && <InteractionValidationErrorList errors={[errors.message]} />}
                                <button type="submit" className="primaryButton" disabled={sendingPreview}>
                                    {sendingPreview ? 'Previewing ... ' : 'Preview'}
                                </button>
                                <button type="button" className="secondaryButton" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
};

const PreviewModal = ({ template, handleSubmit, handleGoBackButton }) => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = () => {
        setSubmitting(true);
        handleSubmit();
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            <TemplateView template={template} showTimestamp={false}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" className="primaryButton" onClick={onSubmit} disabled={submitting}>
                                {submitting ? 'Submitting ...' : 'Submit'}
                            </button>
                            <button type="button" className="secondaryButton" onClick={handleGoBackButton} disabled={submitting}>
                                Go Back
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

const SubmittedModal = () => {
    const { handleModal } = useContext(ModalContext);
    return (
        <div>
            <p>
                Successfully submitted the template. You may now close this window.
            </p>
            <button type="button" className="primaryButton" onClick={() => handleModal()}>
                Close
            </button>
        </div>
    );
}

const DeleteModal = () => {
    const { selected, reloadTemplates } = useContext(TemplateContext);
    const { handleModal } = useContext(ModalContext);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        const url = `${config.system.baseApiUrl}/templates/${selected.id}`;

        axios.delete(url)
            .then(res => {
                setError(null);
                handleModal();
                reloadTemplates();
            }).catch(error => {
                setError(error.response.data.errors ?? error);
            });
    }

    if (error) {
        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {error}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            Are you sure you want to permanently delete the template?
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" onClick={() => handleModal()}>
                                Cancel
                            </button>
                            <button type="button" className="dangerButton" onClick={handleDelete}>
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TemplateModal;
