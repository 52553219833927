import React from 'react';
import ModalBackground from '../ModalBackground/ModalBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import axios from 'axios';
import { config } from '../../utils/config';
moment.tz.setDefault('Australia/Brisbane');

const Notifications = ({ notifications, handleClose }) => {
    const hasNotifications = notifications.length >= 1;

    const handleOnClick = (e) => {
        // Mark the notifications as read.
        e.preventDefault();
        const url = `${config.system.baseApiUrl}/notifications`;
        axios.post(url, {});
        notifications.forEach(element => {
            element.read_at = moment();
        });
        handleClose(e);
    }

    return (
        <React.Fragment>
            <ModalBackground handleClose={handleClose} />
            <aside id="Notifications" className="topLeft">
                <div className='notifications-header'>
                    <h3>Notifications</h3>
                    <a href="" className='notification-markall' onClick={handleOnClick}>Mark all as read</a>
                    <div className="closeButton">
                        <FontAwesomeIcon icon={faTimesCircle} onClick={handleClose} />
                    </div>
                </div>
                {!hasNotifications && <p>You have no notifications.</p>}
                {notifications.map( (notification, index) => <AnnouncementNotification key={index} notification={notification}/>)}
            </aside>
        </React.Fragment>
    )
}

const AnnouncementNotification = ({notification}) => {
    const action = `/announcements/${notification.data.id}`;
    const message = notification.data.summary;
    const createdWhen = moment.duration(moment(notification.created_at).diff(moment())).humanize();

    return (
        <Notification action={action} notification={notification}>
            <div className='notification-info'>
                <span className='notification-info-what'>A new announcement was created:</span>
                <span className='notification-info-when' title={notification.created_at}>{createdWhen} ago</span>
            </div>
            <div className='notification-body'>
                {message}
            </div>
        </Notification>
    );
};

const Notification = ({action, children, notification}) => {
    const hasBeenRead = notification.read_at !== null;

    const handleOnClick = (e) => {
        if (notification.read_at !== null) {
            return;
        }

        // Mark the notification as read.
        e.preventDefault();
        const url = `${config.system.baseApiUrl}/notifications`;
        axios.post(url, {id: notification.id}).finally( () => window.location.href = action);
    }

    return (
        <div className={hasBeenRead ? 'notification read': 'notification'}>
            <a href={action} title={"Click to view full notification"} onClick={handleOnClick}>
                {children}
            </a>
        </div>
    );
};


export default Notifications;