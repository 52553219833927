import React from 'react';
import { CrmCasesListItem } from './index';

const CrmCasesList = ({ items }) => (
    <div className="feedbackList">
        <h4>Interactions ({items.length})</h4>
        {items.map((item, index) => <CrmCasesListItem item={item} key={index} />)}
    </div>
)

export default CrmCasesList;