import React from 'react';
import useRouteParams from '../../../../hooks/useRouteParams';
import { CURRENT_VET_YEAR_LABEL } from '../../../../utils/constants';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';

const OverviewWidget = ({ week, unit, enrollments, isCurrentTerm, isVetTerm }) => {
    const {year, term} = useRouteParams();
    const isCurrentHE = isCurrentTerm && !isVetTerm,
          isCurrentVET = isCurrentTerm && isVetTerm,
          isHistoricHE = !isCurrentTerm && !isVetTerm,
          isHistoricVET = !isCurrentTerm && isVetTerm;

    // Determine the statuses array and heading based on the conditions. Default is isCurrentHE.
    let statuses = ['ENR', 'FAIL', 'WD'];
    let heading = `${unit.code} Overview`;
    if (isCurrentHE) {
        heading = `${unit.code} ${week.long_name} Overview`;
    }
    if (isCurrentVET || isHistoricVET) {
        statuses = ['PASS', 'ENR', 'WD'];
        heading = `${unit.code} ${year} VET Overview`;
    }
    if (isHistoricHE) {
        statuses = ['PASS', 'FAIL', 'WD'];
        heading = `${unit.code} HE ${year} Term ${term} Overview`;
    }
    if (isCurrentVET) {
        heading = `${unit.code} ${CURRENT_VET_YEAR_LABEL} VET Overview`;
    }

    // Filter the enrollments based on the statuses array and additional condition
    const filtered = enrollments.filter(enrollment => statuses.includes(enrollment.status_code) || (isHistoricVET));

    const { loggedInThisWeek, lowPassRate } = filtered.reduce((counts, enrollment) => {
        if (enrollment.lastLoginBand === 'This week') {
            counts.loggedInThisWeek++;
        }
        if (enrollment.passRateBand === 'Low') {
            counts.lowPassRate++;
        }
        return counts;
    }, { loggedInThisWeek: 0, lowPassRate: 0 });

    return (
        <div className="UnitOverview">
            <h1>{heading}</h1>
            <div className="UnitInfoBar">
                {(isHistoricHE || isHistoricVET) && <div className="UnitInfo">
                    <h1>{enrollments.filter(enr => enr.status_code === 'PASS').length}</h1>
                    <h2>Passed Students</h2>
                </div>}
                {(isCurrentHE || isCurrentVET || isHistoricVET) && <div className="UnitInfo">
                    <h1>{enrollments.filter(enr => enr.status_code === 'ENR').length}</h1>
                    <h2>Enrolled Students<DefinitionInfo code={DefinitionCodes.ENROLMENT} /></h2>
                </div>}
                {isHistoricHE && <div className="UnitInfo">
                    <h1>{enrollments.filter(enr => enr.status_code === 'FAIL').length}</h1>
                    <h2>Failed Students</h2>
                </div>}
                {!isCurrentTerm && <div className="UnitInfo">
                    <h1>{enrollments.filter(enr => enr.status_code === 'WD').length}</h1>
                    <h2>Withdrawn Students</h2>
                </div>}
                {isCurrentTerm && <div className="UnitInfo">
                    <h1>{filtered.length !== 0 ? Math.round(100 * loggedInThisWeek / filtered.length) : '- '}%</h1>
                    <h2>Have logged in this week<DefinitionInfo code={DefinitionCodes.MOODLE_LOGINS} /></h2>
                </div>}
                <div className="UnitInfo">
                    <h1>{filtered.length !== 0 ? Math.round(100 * lowPassRate / filtered.length) : '- '}%</h1>
                    <h2>Have a low pass rate<DefinitionInfo code={DefinitionCodes.PASS_RATE} /></h2>
                </div>
            </div>
        </div>
    )
}

export default OverviewWidget;