import { useContext } from "react";
import { DefinitionContext } from "../../../../context/DefinitionContext";
import { getDefinitionModifiedBy } from "../../../../utils/utils";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const DefinitionListItem = ({definition}) => {
    const {id, code, content, updated_at} = definition;
    const {selected, setSelected, setSoftSelected} = useContext(DefinitionContext);
    const active = selected && selected.id == id ? true : false;

    const handleOnHover = (definition) => {
        if (selected) {
            return; // Don't do anything if a selection is made.
        }

        setSoftSelected(definition);
    }

    return (
        <div
            className={`DefinitionListItemContainer ${active ? 'active' : ''}`}
            onMouseEnter={() => handleOnHover(definition)}
            onClick={() => setSelected(definition)}
        >
            <div className="DefinitionListItemSummary">{code}</div>
            <div className="DefinitionListItemDetails">
                <div className="updated">{moment.duration(moment(updated_at).diff(moment())).humanize()} ago</div>
            </div>
        </div>
    );
};

export default DefinitionListItem;