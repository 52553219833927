import { useContext } from "react";
import { AnnouncementContext } from "../../../../context/AnnouncementContext";
import { getAnnouncementModifiedBy } from "../../../../utils/utils";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const AnnouncementListItem = ({announcement}) => {
    const {id, title, summary, author, editor, updated_at} = announcement;
    const {selected, setSelected, setSoftSelected} = useContext(AnnouncementContext);
    const active = selected && selected.id == id ? true : false;

    const handleOnHover = (announcement) => {
        if (selected) {
            return; // Don't do anything if a selection is made.
        }

        setSoftSelected(announcement);
    }

    return (
        <div
            className={`AnnouncementListItemContainer ${active ? 'active' : ''}`}
            onMouseEnter={() => handleOnHover(announcement)}
            onClick={() => setSelected(announcement)}
        >
            <div className="AnnouncementListItemSummary">{summary}</div>
            <div className="AnnouncementListItemDetails">
                <div className="author">{getAnnouncementModifiedBy(author, editor)}</div>
                <div className="updated">{moment.duration(moment(updated_at).diff(moment())).humanize()} ago</div>
            </div>
        </div>
    );
};

export default AnnouncementListItem;