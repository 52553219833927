import { useContext } from "react";
import { TemplateContext } from "../../context/TemplatesContext";

const InteractionTemplateInsertButton = ({handleTextInsert, handleClose}) => {
    const { selected } = useContext(TemplateContext);

    const handleOnClick = () => {
        handleTextInsert(selected.template);
        handleClose();
    }

    return (
       <button className="primaryButton" onClick={handleOnClick}>Insert</button>
    );
}

export default InteractionTemplateInsertButton;
