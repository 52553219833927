import React, { useContext } from 'react';
import { renderSiNumber, renderXLabel, renderYLabel, withWidget } from '../../../../utils/graph';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { mapBeginDateToShortDates, mapBeginDateToWeeks } from '../../../../utils/calendar';
import { AssetsContext } from '../../../../context/AssetsContext';
import { config } from '../../../../utils/config';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';

const PLOT = {
    width: 480,
    height: 280,
    left: 80,
    bottom: 60,
    yDomain: [0, 10]
};

const WeeklyActivityGraph = ({ year, term, enrollments, hover, handleMouseOver, handleMouseOut, }) => {
    const assets = useContext(AssetsContext);

    const weeks = assets.terms.find(elem => elem.year === parseInt(year) && elem.term === term).weeks.data;

    // Each myExperience needs to be mapped against a weeks week.
    const data = weeks.map((week) => {
        const total = enrollments.reduce((acc, enrollment) => {
            const activities = enrollment.activities.filter(activity => activity.begin_date === week.start);

            if (!activities || activities.length === 0) {
                return acc;
            }

            activities.forEach(activity => acc += activity.activity);
            return acc;
        }, 0);

        return {
            x: week.start,
            y: total,
            description: week.long_name,
            color: hover.x === week.start ? config.colors.cquBlue50 : config.colors.cquGreen,
        };
    });

    return (
        <div className={term === 'VET' ? "UnitWidget UnitWidgetAutoWidth" : "UnitWidget UnitWidgetWide"}>
            <h4>Total Weekly Moodle Activity<DefinitionInfo code={DefinitionCodes.ACTIVITY} /></h4>
            <div>
                <XYPlot
                    height={PLOT.height}
                    width={term === 'VET' ? PLOT.width *2 : PLOT.width}
                    xType="ordinal"
                    className={data.length === 0 ? "empty" : ""}
                    margin={{
                        left: PLOT.left,
                        bottom: PLOT.bottom,
                    }}
                >
                    {hover && (
                        <Hint value={hover} className='plotTooltip' >
                            <div>
                                <h3>{hover.description}</h3>
                                <p>{hover.y}</p>
                            </div>
                        </Hint>
                    )}
                    {renderXLabel("Week of Term", PLOT)}
                    {term === 'VET' ? (
                        <XAxis
                            height={PLOT.bottom}
                            tickFormat={val => mapBeginDateToShortDates(val, weeks)}
                            tickLabelAngle={-90}
                        />
                    ) : (
                        <XAxis
                            height={PLOT.bottom}
                            tickFormat={val => mapBeginDateToWeeks(val, weeks)}
                        />
                    )}
                    {renderYLabel("Moodle Activity", PLOT)}
                    <YAxis width={PLOT.left} tickFormat={renderSiNumber} />
                    <HorizontalGridLines />
                    <VerticalBarSeries
                        colorType={"literal"}
                        data={data}
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                    />
                </XYPlot>
            </div>
        </div>
    );
}

export default withWidget(WeeklyActivityGraph);