import React, { useContext } from "react";
import { HeaderContext } from "../../../../context/HeaderContext";
import { ModalContext } from "../../../../context/ModalContext";
import { CRUD_ACTIONS } from "../../../../utils/constants";
import { CategoryModal, TemplateModal }from "../../components";
import { TemplateContext } from "../../../../context/TemplatesContext";

const TemplateViewButtonList = () => {
    const {user} = useContext(HeaderContext);
    const {selected} = useContext(TemplateContext);
    const {handleModal} = useContext(ModalContext);

    // As per requirements only trainers are allowed.
    if (!['admin', 'trainer'].includes(user.level)) {
        return <></>;
    }

    return (
        <div className="ButtonListContainer">
            <div>
                <button className="primaryButton" onClick={() => handleModal(<TemplateModal action={CRUD_ACTIONS.CREATE}/>)}>
                    Create
                </button>
                <button onClick={() => handleModal(<CategoryModal action={CRUD_ACTIONS.CREATE}/>)}>
                    Create Category
                </button>
            </div>
            {selected && <div>
                <button onClick={() => handleModal(<TemplateModal action={CRUD_ACTIONS.UPDATE} />)}>
                    Edit
                </button>
                <button className="dangerButton" onClick={() => handleModal(<TemplateModal action={CRUD_ACTIONS.DELETE}/>)}>
                    Delete
                </button>
            </div>}
        </div>
    );
}

export default TemplateViewButtonList;
