import React, {createContext, useEffect, useState} from "react";
import { fetchDefinitions } from "../utils/fetch";
import { sendLog } from "../utils/utils";
import { LOG } from "../utils/constants";

/**
 * DefinitionContext contains all definitions.
 */
const DefinitionContext = createContext(null);

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 */
const DefinitionContextProvider = ({ children }) => {
    const [error, setError] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [definitions,  setDefinitions]  = useState([]);
    const [selected, setSelected] = useState(undefined);
    // A soft selected definition is the first one in the list returned,
    // and when user hovers over a definition.
    const [softSelected, setSoftSelected] = useState(undefined);

    // Load the definitions.
    useEffect(() => {
        reloadDefinitions();
    }, []);

    const handleSetSelected = (definition) => {
        setSelected(definition);
        if (definition?.code) {
            sendLog("App\\Events\\Definition\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.DEFINITION, LOG.DASHBOARD.DEFINITIONS, {
                code: definition.code,
            });
        }
    }

    /** Function to reload that comes in handy on form handling. */
    const reloadDefinitions = () => {
        setLoading(true);
        fetchDefinitions()
            .then( definitions => {
                setDefinitions(definitions);
                if (!selected) {
                    setSoftSelected(definitions[0]);
                } else {
                    setSoftSelected();
                    handleSetSelected(definitions.find(item => item.id === selected.id));
                }
            })
            .catch( error => setError(error))
            .finally( () => setLoading(false));
    };

    // Create the assets object that will be passed through.
    const isError   = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        definitions,
        setDefinitions,
        error,
        isError,
        isLoading,
        isSuccess,
        selected,
        setSelected: handleSetSelected,
        softSelected,
        setSoftSelected,
        reloadDefinitions,
    };

    return (
        <DefinitionContext.Provider value={value}>
            {children}
        </DefinitionContext.Provider>
    );
};

export { DefinitionContext, DefinitionContextProvider };