import React, { Component } from "react";
import { Link } from "react-router-dom";
import { XYPlot, YAxis, VerticalBarSeries } from "react-vis";

import { config } from '../../utils/config';
import { GraphLegend } from '../GraphLegend/GraphLegend';
import { FilterLink } from '../FilterLink/FilterLink';
import { DefinitionInfo } from '../../components';

import {
  unsetParam,
  uriLink,
  toggleParams,
  readQueryString,
} from "../../utils/uriParams";

import {
  chunk_square,
  color_scheme,
  getValueByProperty,
  make_object,
} from "../../utils/utils";

import {
  VET_PARTICIPATED,
  VET_NOT_PARTICIPATED,
} from "../../utils/constants";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPoll,
  faPollH,
  faTasks as faCheckAll,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSquare as faSquareOutline,
} from "@fortawesome/free-regular-svg-icons";
import { AssetsContext } from "../../context/AssetsContext";

library.add(
  faCheckAll,
  faPoll,
  faPollH,
  faSquareOutline,
  faTimesCircle,
);

/**
 * ----------------------------------------------------------------------
 * A FilterBox allows bar graph and table views, and can start in either one.
 * ----------------------------------------------------------------------
 */

class FilterBox extends Component {

  static contextType = AssetsContext;

  constructor(props) {
    super(props);
    this.state = {
      showTable: props.showTable === undefined || props.showTable === true,
    }
    this.handleShowTable = this.handleShowTable.bind(this);
  };

  handleShowTable(event) {
    event.stopPropagation();
    this.setState({
      showTable: this.state.showTable === true ? false : true,
    });
  }

  render() {
    if (this.props.disabled) {
      return <></>;
    }

    //  Only render when all properties are found.

    if (
      this.props.title === undefined ||
      this.props.propName === undefined ||
      this.props.paramName === undefined ||
      this.props.loaded === undefined ||
      this.props.filtered === undefined
    ) {
      return (
        <React.Fragment>
          <h4>FilterBox</h4>
          <p>Missing parameters</p>
          <div>{JSON.stringify(Object.keys(this.props))}</div>
        </React.Fragment>
      );
    }

    const title = this.props.title;
    const propName = this.props.propName;
    const paramName = this.props.paramName;
    const params = readQueryString(config.defaultParams, window.location.search);
    const loaded = this.props.loaded;
    const filtered = this.props.filtered;
    const showTable = this.state.showTable;
    const hash = this.props.hash ? this.props.hash : '';
    let hints = this.props.hints ? this.props.hints : {};  // <-- mouseover
    const sort = this.props.sort ? this.props.sort : '';
    const definition = this.props.definition;

    if (params[paramName] === undefined) {
      return (
        <React.Fragment>
          <h4>FilterBox</h4>
          <p>Missing param: {paramName}</p>
          <div>{JSON.stringify(Object.keys(params))}</div>
        </React.Fragment>
      );
    }

    //  ------------------------------------------------------------
    //  Legend property is optional; if missing then collate the
    //  values in the result set.

    let legend = [];
    if (this.props.legend !== undefined) {
        legend = this.props.legend;
    } else {
        let legendSet = loaded.reduce((acc, prediction) => {
        const value = getValueByProperty(propName, prediction);
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            value.forEach(_ => acc.add(_));
          } else {
            acc.add(value);
          }
        }
        return acc;
      }, new Set());
    
      // Performs what is called a normal sort. Example [Group 1, Group 10, Group 2] becomes [Group 1, Group 2, Group 10].
      legend =  [...legendSet].sort(new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare);
    }
  
    const colorScheme = color_scheme(legend, config.scheme.calm);

    const totals = filtered.reduce((acc, prediction) => {
      const value = getValueByProperty(propName, prediction);
      if (value !== undefined && value !== null) {
        //  ------------------------------------------------------------
        //  The property in the prediction line may be an scalar like unitCode
        //  or Array like flags.
        if (Array.isArray(value)) {
          value.forEach(legendKey => (acc[legendKey] += 1));
        } else {
          acc[value] += 1;
        }
      }
      return acc;
    }, make_object(legend, 0));

    const graphData = legend.map((_, index) => {
      return {
        x: index,
        y: totals[_],
        color: colorScheme[_]
      };
    });

    //  console.log("graphData", graphData);

    const legendColumns = chunk_square(legend, 7);

    //  console.log("legendColumns", legendColumns);

    const linkToUnsetFilter = uriLink(unsetParam(params, paramName), config.defaultParams) + hash;

    const allParams = Object.keys(totals).reduce((carry, key) => {
      const params = toggleParams(carry, paramName, key);
      return params;
    }, params);

    const linkToSetAllFilter = uriLink(allParams, config.defaultParams) + hash;

    //  console.log(legendColumns);

    let tableColumns = Object.keys(totals);

    if (sort === 'monthSort') {
      const allMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      tableColumns = Object.keys(totals).sort((a, b) => allMonths.indexOf(a) - allMonths.indexOf(b));
    } else if (sort === 'vetParticipationSort') {
      const allVP = VET_PARTICIPATED.concat(VET_NOT_PARTICIPATED);
      tableColumns = Object.keys(totals).sort((a, b) => allVP.indexOf(a) - allVP.indexOf(b));
    }

    // We can use the the asset in assets context for getting the hints.
    if (this.props.asset !== undefined && this.props.asset !== '' && this.context.isSuccess) {
      hints = this.context[this.props.asset].reduce((result, {code, name}) => {
        result[code] = name;
        return result;
      }, {});
    }

    return (
      <section className="filterBox pieChart">
        <h4>
          <FontAwesomeIcon
            style={{
              float: 'right',
              paddingLeft: '.5rem',
              color: config.colors.cquBlue50,
              cursor: 'pointer',
            }}
            icon={showTable ? faPoll : faPollH}
            onClick={this.handleShowTable}
            title="Toggle Graph"
          />
          <span
            style={{
              cursor: 'pointer',
            }}
            icon={showTable ? faPoll : faPollH}
            title="Select All"
          >
            {params[paramName].length > 0 ? (
              <Link to={linkToUnsetFilter}>
                <FontAwesomeIcon
                  style={{
                    maxWidth: '1rem',
                    paddingRight: '.265rem',
                    color: config.colors.cquGreen,
                  }}
                  icon={faTimesCircle}
                />
                {title}
              </Link>
            ) : (
              <Link to={linkToSetAllFilter}>
                <FontAwesomeIcon
                  style={{
                    maxWidth: '1rem',
                    paddingRight: '.39rem',
                    color: config.colors.cquBlue50,
                  }}
                  icon={faSquareOutline}
                />
                {title}
              </Link>
            )}
            <DefinitionInfo code={definition} />
          </span>
        </h4>
        {showTable ? (
          <div className="verticalScroll">
            <div className="Ctable dataTable">
              {tableColumns.map((key, index) => (
                <div className="row" key={index}>
                  <div className="col no-wrap">
                    <FilterLink
                      label={key}
                      paramName={paramName}
                      params={params}
                      scheme={colorScheme}
                      total={totals[key]}
                      icon={faSquareOutline}
                      color={config.colors.cquLightCharcoal}
                      hash={hash}
                      hint={hints[key] ?? ''}
                    />
                  </div>
                  <div className="col">
                    {totals[key]}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="barShadow">
                    <XYPlot
                      width={200}
                      height={180}
                    >
                      <YAxis />
                      <VerticalBarSeries
                        colorType={"literal"}
                        data={graphData}
                      />
                    </XYPlot>
                  </div>
                </td>
                {legendColumns.map((legendColumn, index) => {
                  return (
                    <td key={index}>
                      <GraphLegend
                        propName={propName}
                        paramName={paramName}
                        params={params}
                        legend={legendColumn}
                        scheme={colorScheme}
                        totals={totals}
                        hash={hash}
                        hints={hints}
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        )}
      </section>
    );
  }
}

export {
  FilterBox,
}
