import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

/**
 * A table header cell that displays a button indicating if it's currently sorted by this column and in which direction,
 *  and handles user interaction.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to be displayed in the header cell.
 * @param {string} props.sortConst - A constant representing the sort order for this column.
 * @param {string} props.sort - The current sort order being used for the table.
 * @param {boolean} props.direction - The current direction of the sort order being used for the table.
 * @param {Function} props.handleClick - A function to be called when the user clicks on the header cell, which should update the sorting order for the table.
 * @returns {JSX.Element} - A table header cell with an interactive button for sorting.
 */
const InteractionTableSortableHeaderCell = ({text, sortConst, sort, direction, handleClick, children}) => (
    <td width="10%" onClick={() => handleClick(sortConst)}>
        <div className={sort === sortConst ? 'orderButton orderButtonPrimary' : 'orderButton'}>
            <span><FontAwesomeIcon icon={ sort === sortConst && !direction ? faArrowCircleUp : faArrowCircleDown}/> {text}{children}</span>
        </div>
    </td>
);

export default InteractionTableSortableHeaderCell;