// All available definition terms
const DefinitionCodes = {
    ACTIVITY: 'Activity',
    ACTIVITY_INTAKE: 'Activity Intake',
    ACTIVITY_START_DATE: 'Activity Start Date',
    ACTIVITY_END_DATE: 'Activity End Date',
    AGE: 'Age',
    ASSESSMENTS: 'Assessments',
    ASSESSMENT_DUE_DATE: 'Assessment Due Date',
    ASSESSMENT_EXTENSIONS: 'Assessment Extensions',
    ASSESSMENT_SUBMISSIONS: 'Assessment Submissions',
    ASSESSMENT_TYPE: 'Assessment Type',
    ASSESSMENT_VISIBILITY_STATUS: 'Assessment Visibility Status',
    CAMPUS: 'Campus',
    CASE_TYPE: 'Case Type',
    COLLEGE: 'College',
    COURSE: 'Course',
    COURSE_PROGRESS: 'Course Progress',
    CREDIT: 'Credit',
    CRM_CASE: 'CRM Case',
    DAYS_ENROLLED: 'Days Enrolled',
    ENROLMENT: 'Enrolment',
    ENTRY_PATHWAY: 'Entry Pathway',
    EXTENDED: 'Extended',
    GENDER: 'Gender',
    GRADE: 'Grade',
    GRADE_POINT_AVERAGE: 'Grade Point Average (GPA)',
    INTAKE_MONTH_YEAR: 'Intake Month / Year',
    INTERACTIONS: 'Interactions',
    LIABILITY: 'Libability',
    LOAD: 'Load',
    MAP: 'MAP',
    MOODLE: 'Moodle',
    MOODLE_ACTIVITY: 'Moodle Activity',
    MOODLE_GRADEBOOK: 'Moodle Gradebook',
    MOODLE_GROUPS: 'Moodle Groups',
    MOODLE_LAST_ACCESS: 'Moodle Last Access',
    MOODLE_LOGINS: 'Moodle Logins',
    MY_EXPERIENCE: 'My Experience',
    MY_EXPERIENCE_RESPONSES: 'My Experience Responses',
    NAME: 'Name',
    PASS_RATE: 'Pass Rate',
    PRIOR_ATTEMPTS: 'Prior Attempts',
    PRIOR_FAILS: 'Prior Fails',
    SCHOOL: 'School',
    STRATEGY: 'Strategy',
    STUDENT_FLAG: 'Student Flag',
    STUDENT_ID: 'Student ID',
    STUDY_PERIOD: 'Study Period',
    SUCCESS_STRATEGY: 'Success Strategy',
    UNIT: 'Unit',
    UNIT_STATUS: 'Unit Status',
    VET_PARTICIPATION: 'VET Participation',
}

export default DefinitionCodes;