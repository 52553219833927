import React, { useContext } from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadialChart } from 'react-vis';
import { AssetsContext } from '../../../../context/AssetsContext';
import { ModalContext } from '../../../../context/ModalContext';
import { config } from '../../../../utils/config';
import { withWidget } from '../../../../utils/graph';
import { color_scheme, sendLog } from '../../../../utils/utils';
import { sortBy } from 'lodash';
import UnitWidgetModal from '../UnitWidgetModal/UnitWidgetModal';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';
import { CURRENT_VET_YEAR_VALUE, LOG } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';

const CoursesGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const assets = useContext(AssetsContext);
    const { year, term, unit } = useRouteParams();
    const { handleModal } = useContext(ModalContext);
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    const result = enrollments.reduce((acc, enrollment) => {
        const course_id = enrollment.course_id;
        acc[course_id] = acc[course_id] || { angle: 0 };
        acc[course_id].angle++;
        return acc;
    }, {});

    const data = Object.keys(result).map((key) => {
        const course = assets.courses.find(({ id }) => id === parseInt(key));
        let label = course.code;

        if (year === CURRENT_VET_YEAR_VALUE) {
            const term = assets.terms.find(({id}) => id === course.term_id);
            label = `${course.code} (${term.year})`
        }

        return {
            label,
            course_id: key,
            angle: result[key].angle,
            description: `${course.code}: ${course.name}`,
            total: result[key].angle,
        }
    });

    const colors = color_scheme(sortBy(data, ['total']).reverse().map(value => value.label), [config.colors.cquGreen, config.colors.cquCharcoal]);

    data.forEach(obj => {
        obj.color = hover.label === obj.label ? config.colors.cquBlue50 : colors[obj.label];
    });

    /**
     *  Open the Modal displaying the cohort of the clicked course.
     */
    const handleOnClick = (data) => {
        const aggregated = enrollments.filter(({ course_id }) => parseInt(data.course_id) === course_id);

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal(<UnitWidgetModal title={`Admitted Courses - ${data.description}`} handleModal={handleModal} cohortFilter={`courses=${data.label}`}/>);
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year, term},
            code: unit,
            aggregation: 'courses',
            value: data.description,
            aggregated: aggregated.length,
        });
    };

    return (
        <div className="UnitWidget">
            <h4>Admitted Courses<DefinitionInfo code={DefinitionCodes.COURSE} /></h4>
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{ flexGrow: '1', marginTop: '1rem', textAlign: 'left', zIndex: '2' }} >
                        {data.slice(0, 9).map((item, index) =>
                            <div className="row clickable" key={index} onClick={() => handleOnClick(item)} onMouseOver={() => handleMouseOver(item)} onMouseOut={() => handleMouseOut(item)}>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    style={{ color: item.color }}
                                /> {item.label}
                            </div>
                        )}
                    </div>
                    <div style={{ marginTop: '1rem', textAlign: 'left', zIndex: '2' }} >
                        {data.slice(9, 19).map((item, index) =>
                            <div className="row clickable" key={index} onClick={() => handleOnClick(item)} onMouseOver={() => handleMouseOver(item)} onMouseOut={() => handleMouseOut(item)}>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    style={{ color: item.color }}
                                /> {item.label}
                            </div>
                        )}
                    </div>
                </div>
                <div style={{position:'absolute', top: '0'}} >
                    <div style={{ position: 'relative', padding: '1rem' }} >
                        <RadialChart
                            colorType={"literal"}
                            radius={90}
                            data={data}
                            width={290}
                            height={180}
                            className="clickable"
                            onValueClick={handleOnClick}
                            onValueMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseOut}
                        >
                        </RadialChart>
                    </div>
                    {hover && (
                        <div style={{ position: 'relative', bottom: '20px', width: '100%', textAlign: 'center' }}>
                            <div className='plotTooltip'>
                                <h3>{hover.description}</h3>
                                <p>{hover.total}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withWidget(CoursesGraph);