import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { config } from "../../../../utils/config";

const BoomiApiDebuggingForm = (props) => {
    return (
        <Formik
            initialValues={ props.initialValues ? props.initialValues : {boomi_api_debugging: false}}
            onSubmit={(values, { setSubmitting }) => {
                axios
                    .post(config.system.baseApiUrl + "/config/boomi-api-debugging", values)
                    .then(() => setSubmitting(false));
            }}
        >
            {({ values, isSubmitting }) => (
                <Form classname="siteConfigForm">
                    <table style={{width: '18rem'}} className="oddEvenRows">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    Only enable this if we need to troubleshoot something on the Boomi side. Normally, Boomi will not log the normal processing of any API calls made without this header (in exchange for a small performance boost), but including the header will re-enable this logging.
                                </td>
                            </tr>
                            <tr>
                                <td>Enabled:</td>
                                <td>
                                    <Field type="checkbox" name="boomi_api_debugging" />
                                    <ErrorMessage name="boomi_api_debugging" component="div" className="errorMessage"/>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting ...' : 'Submit'}</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </Form>
            )}
        </Formik>
    );
}

export { BoomiApiDebuggingForm };
