import React, {createContext, useEffect, useState} from "react";
import { fetchTemplates, fetchTemplateCategories } from "../utils/fetch";
import { sendLog } from "../utils/utils";
import { LOG } from "../utils/constants";

/**
 * TemplateContext contains all templates and categories.
 */
const TemplateContext = createContext(null);

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 */
const TemplateContextProvider = ({ children }) => {
    const [error, setError] = useState(undefined);
    const [templates, setTemplates]  = useState([]);
    const [templatesLoading, setTemplatesLoading] = useState(false);
    const [categories, setCategories]  = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    // A soft selected template is the one which the user hovers over.
    const [softSelected, setSoftSelected] = useState(undefined);
    const [selected, setSelected] = useState(undefined);


    // Load the templates.
    useEffect(() => {
        reloadTemplates();
    }, []);

    const handleSetSelected = (template) => {
        setSelected(template);
        if (template?.id) {
            sendLog("App\\Events\\Template\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.ANNOUNCEMENT, LOG.DASHBOARD.ANNOUNCEMENTS, {
                id: template.id,
            });
        }
    }

    /** Function to reload that comes in handy on form handling. */
    const reloadTemplates = () => {
        setTemplatesLoading(true);
        setCategoriesLoading(true);

        fetchTemplates()
            .then( templates => setTemplates(templates))
            .catch( error => setError(error))
            .finally( () => setTemplatesLoading(false));

        fetchTemplateCategories()
            .then( categories => setCategories(categories))
            .catch( error => setError(error))
            .finally( () => setCategoriesLoading(false));

        setSelected();
        setSoftSelected();
    };

    // Create the assets object that will be passed through.
    const isLoading = templatesLoading && categoriesLoading;
    const isError   = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        templates,
        setTemplates,
        categories,
        setCategories,
        error,
        isError,
        isLoading,
        isSuccess,
        selected,
        setSelected : handleSetSelected,
        softSelected,
        setSoftSelected,
        reloadTemplates,
    };

    return (
        <TemplateContext.Provider value={value}>
            {children}
        </TemplateContext.Provider>
    );
};

export { TemplateContext, TemplateContextProvider };
