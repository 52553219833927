import React from 'react';
import { hide_string } from '../../../../utils/utils';
import { config } from '../../../../utils/config';
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const StudentDetails = ({ incognito, student, enrollments }) => {

    /** Renders the string depending on incognito mode. */
    const renderIncognito = (str, len) => {
        return incognito ? hide_string(str, len) : str;
    }

    const flags = Array.from(enrollments.reduce( (acc, obj) => {
        obj.flags.forEach( flag => acc.add(flag));
        return acc;
    }, new Set()));

    return (
        <div className="shadowBox">
            <table className="dataTable">
                <tbody>
                    <tr>
                        <th>Student ID</th><td>{renderIncognito(student.code)}</td>
                    </tr>
                    <tr>
                        <th>Student One ID</th><td>{renderIncognito(student.id)}</td>
                    </tr>
                    <tr>
                        <th>Name</th><td>{renderIncognito(`${student.last_name}, ${student.first_name}`)}</td>
                    </tr>
                    <tr>
                        <th>Birthdate</th><td>{renderIncognito(moment(student.date_birth.slice(0, 10)).format('DD MMMM YYYY'))}</td>
                    </tr>
                    <tr>
                        <th>Email</th><td><a href={'mailto:' + student.email}>{renderIncognito(student.email)}</a></td>
                    </tr>
                    <tr>
                        <th>Day Phone</th><td><a href={'tel:' + student.phone_work}>{renderIncognito(student.phone_work)}</a></td>
                    </tr>
                    <tr>
                        <th>Evening Phone</th><td><a href={'tel:' + student.phone_home}>{renderIncognito(student.phone_home)}</a></td>
                    </tr>
                    <tr>
                        <th>Mobile</th><td><a href={'tel:' + student.phone_mobile}>{renderIncognito(student.phone_mobile)}</a></td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>
                            <div className="flexRow">
                                <div className="leftHalfPadded wrapText">
                                    <div>{renderIncognito(student.address_postal_line1)}</div>
                                    {student.address_postal_line2 !== null && (
                                        <div>{renderIncognito(student.address_postal_line2)}</div>
                                    )}
                                    {student.address_postal_line3 !== null && (
                                        <div>{renderIncognito(student.address_postal_line3)}</div>
                                    )}
                                    <div>{renderIncognito(student.address_postal_suburb)}</div>
                                    <div>{renderIncognito(student.address_postal_state)} {renderIncognito(student.address_postal_postcode)} {renderIncognito(student.address_postal_country)}</div>
                                    <div style={{ textAlign: 'right', fontSize: 'small', color: config.colors.cquCharcoal, paddingTop: '0.5rem' }}>{student.address_postal_type}</div>
                                </div>
                                <div className="leftHalfPadded wrapText">
                                    <div>{renderIncognito(student.address_home_line1)}</div>
                                    {student.address_home_line2 !== null && (
                                        <div>{renderIncognito(student.address_home_line2)}</div>
                                    )}
                                    {student.address_home_line3 !== null && (
                                        <div>{renderIncognito(student.address_home_line3)}</div>
                                    )}
                                    <div>{renderIncognito(student.address_home_suburb)}</div>
                                    <div>{renderIncognito(student.address_home_state)} {renderIncognito(student.address_home_postcode)} {renderIncognito(student.address_home_country)}</div>
                                    <div style={{ textAlign: 'right', fontSize: 'small', color: config.colors.cquCharcoal, paddingTop: '0.5rem' }}>{student.address_home_type}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Gender</th>
                        <td>{renderIncognito(student.gender)}</td>
                    </tr>
                    <tr>
                        <th>Age</th>
                        <td>{renderIncognito(student.age)}</td>
                    </tr>
                    <tr>
                        <th>Student Flag</th>
                        <td>{renderIncognito(flags.join(", "))}</td>
                    </tr>
                    <tr>
                        <th>Deceased</th>
                        <td>{renderIncognito(student.deceased)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default StudentDetails;