import React from 'react'
import Select from 'react-select'
import { config } from '../../../utils/config';

const options = config.flags.map( (element => {
    return { value: element.replace(' ', "_"), label: element };
}));

const FlagSelect = ({ enabled, value, onChange }) => {

    return (
        <Select
            isMulti
            isDisabled={!enabled }
            options={options}
            value={value}
            placeholder="Student Flag ..."
            onChange={onChange}
            classNamePrefix="react-select"
        />
    );
}

export default FlagSelect;
