import React from 'react';

/**
 * A text area component with a character limit.
 *
 * @param {object} props Component props.
 * @param {number} props.limit Maximum number of characters allowed. Default is 140.
 * @param {string} props.placeholder Placeholder text for the textarea.
 * @param {string} props.value Initial and current value of the textbox.
 * @param {function} props.onValueChange Callback function to update the value.
 * @param {number} props.defaultRows How many rows are shown.
 * @returns {JSX.Element} The text area component.
 */
function CharacterLimitedTextBox({ limit = 140, placeholder, value, onValueChange, defaultRows }) {
  const charactersLeft = limit - value.length;
  const rows = defaultRows || Math.ceil(limit / 40); // Assuming 40 characters per row

  const handleChange = (event) => {
    if (event.target.value.length <= limit) {
      onValueChange(event.target.value);
    }
  };

  const style = {};

  if (defaultRows) {
    style.height = 'unset';
  }

  return (
    <div>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        maxLength={limit}
        rows={rows}
        style={style}
      />
      <div className="character-count">
        {charactersLeft} characters left
      </div>
    </div>
  );
}

export default CharacterLimitedTextBox;