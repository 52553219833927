import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalBackground from "../ModalBackground/ModalBackground";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { TemplateContextProvider } from "../../context/TemplatesContext";
import InteractionTemplateSelectPreview from "./InteractionTemplateSelectPreview";
import InteractionTemplateSelectList from "./InteractionTemplateSelectList";
import InteractionTemplateInsertButton from "./InteractionTemplateInsertButton";

const InteractionTemplateSelect = ({handleClose, handleTextInsert}) => {
    return (
        <TemplateContextProvider>
            <ModalBackground handleClose={handleClose} />
            <aside id="Templates" className="middleCenter smaller flex-container">
                <div className="templates-header flex-container align-items-center">
                    <h3 className="grow">Templates</h3>
                    <div className="closeButton" style={{float: 'right'}}>
                        <FontAwesomeIcon icon={faTimesCircle} onClick={handleClose} />
                    </div>
                </div>

                <div className="templates-main grid-container">
                    <InteractionTemplateSelectList />
                    <InteractionTemplateSelectPreview />
                </div>

                <div className="templates-footer flex-container align-items-center">
                    <div className="grow"/>
                    <div>
                        <button onClick={handleClose}>Cancel</button>
                        <InteractionTemplateInsertButton handleClose={handleClose} handleTextInsert={handleTextInsert} />
                    </div>
                </div>
            </aside>
        </TemplateContextProvider>
    );
};

export default InteractionTemplateSelect;
