import React, { useContext } from 'react';
import { renderSiNumber, renderXLabel, renderYLabel, withWidget } from '../../../../utils/graph';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { mapBeginDateToWeeks } from '../../../../utils/calendar';
import { AssetsContext } from '../../../../context/AssetsContext';
import { ModalContext } from '../../../../context/ModalContext';
import MyExperienceModal from './MyExperienceModal';
import { config } from '../../../../utils/config';
import { sendLog } from '../../../../utils/utils';
import { LOG } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';

const PLOT = {
    width: 480,
    height: 230,
    left: 60,
    bottom: 50,
};

const MyExperienceGraph = ({ hover, year, term, enrollments, handleMouseOver, handleMouseOut
}) => {
    const assets = useContext(AssetsContext);
    const { handleModal } = useContext(ModalContext);
    const { unit } = useRouteParams();

    const weeks = assets.terms.find( elem => elem.year === parseInt(year) && elem.term === term).weeks.data;

    // Mapping the enrollments my experiences to the weeks weeks.
    // Get all the MyExperiences.
    const allMyExperiences = enrollments.flatMap(enrollment => enrollment.myExperiences);

    const uniqueStudents = new Set();
    let totalScore = 0;

    // Add to total score and add unique student id's.
    allMyExperiences.forEach(element => {
        uniqueStudents.add(element.student_id);
        totalScore += element.rating;
    });
    const average = allMyExperiences.length > 0 ? (totalScore / allMyExperiences.length).toFixed(2) : '0';

    // Each myExperience needs to be mapped against a weeks week.
    const data = weeks.map((week) => {
        const responses = allMyExperiences.filter( element => element.recorded_date >= week.start && element.recorded_date <= week.end );
        const totalScore = responses.reduce( (acc, elm ) => acc += elm.rating, 0 );
        const satisfaction = responses.length > 0 ? (totalScore / responses.length).toFixed(2) : '0';
        const students = responses.reduce((acc, elm) => acc.add(elm.student_id), new Set());

        return {
            x: week.start,
            y: responses.length,
            end_date: week.end,
            description: week.long_name,
            responses: responses,
            students: students.size,
            satisfaction,
            color: hover.x === week.start ? config.colors.cquBlue50 : config.colors.cquBlue80,
        };
    });

    /**
     * Opens the Modal with the responses for that week when it's a bar is clicked.
     */
    const handleClick = (data) => {
        handleModal( <MyExperienceModal weekName={data.description} data={data.responses} /> );
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit,
            aggregation: 'myExperience',
            value: data.description,
            aggregated: data.responses.length,
        })
    }

    return (
        <div className="UnitWidget UnitWidgetWide">
            <h4>My Experience<DefinitionInfo code={DefinitionCodes.MY_EXPERIENCE} /></h4>
            <div>
                Average satisfaction score: <b>{average}</b><br />
                <b>{allMyExperiences.length}</b> responses by <b>{uniqueStudents.size}</b> students
            </div>
            <XYPlot
                xType="ordinal"
                yDomain={PLOT.yDomain}
                width={PLOT.width}
                height={PLOT.height}
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom,
                }}
            >
                {hover && (
                    <Hint value={hover} className='plotTooltip'>
                        <div>
                            <h3>{hover.description}</h3>
                            <p>
                                Satisfaction rate: {hover.satisfaction}<br />
                                Total responses: {hover.responses.length}<br />
                                Distinct students: {hover.students}<br />
                            </p>
                        </div>
                    </Hint>
                )}
                <HorizontalGridLines />
                {renderXLabel("Week of Term", PLOT)}
                <XAxis tickFormat={val => mapBeginDateToWeeks(val, weeks)} />
                {renderYLabel("Responses", PLOT)}
                <YAxis tickFormat={renderSiNumber} />
                <VerticalBarSeries
                    colorType={"literal"}
                    data={data}
                    onValueClick={handleClick}
                    onValueMouseOut={handleMouseOut}
                    onValueMouseOver={handleMouseOver}
                />
            </XYPlot>
        </div>
    );
}

export default withWidget(MyExperienceGraph);