import React, { useContext, useEffect, useRef, useState } from "react";
import { CRUD_ACTIONS, TINYMCE_INIT_SETTINGS } from "../../../../utils/constants";
import { AnnouncementContext } from "../../../../context/AnnouncementContext";
import { config } from "../../../../utils/config";
import { ModalContext } from "../../../../context/ModalContext";
import { CharacterLimitedTextBox } from "../../../../components";
import { Editor } from "@tinymce/tinymce-react";
import { CampusSelect, CollegeSelect, CourseSelect, SchoolSelect, TermSelect } from "../../../Home/components";
import { HeaderContext } from "../../../../context/HeaderContext";
import { getParams } from "../../../../utils/utils";
import axios from "axios";
import InteractionValidationErrorList from "../../../../components/InteractionForm/InteractionValidationErrorList";
import AnnouncementView from "../AnnouncementView/AnnouncementView";

const AnnouncementModal = ({action}) => {
    // Context and references.
    const { selected, reloadAnnouncements } = useContext(AnnouncementContext);
    const { handleModal } = useContext(ModalContext);
    const { terms, selTerm, setSelTerm } = useContext(HeaderContext);
    const editorRef =  useRef(null);

    // States that the value of the announcement.
    const [everyone, setEveryone] = useState(selected?.path === '' ? false : true);
    const [path, setPath] = useState(selected?.path || '');
    const [title, setTitle] = useState(selected?.title || '');
    const [summary, setSummary] = useState(selected?.summary || '');
    const [body, setBody] = useState(selected?.body || '');

    // States for handling the form, and submission.
    const [errors, setErrors] = useState(null);
    const [preview, setPreview] = useState(null);
    const [sendingPreview, setSendingPreview] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selCampuses, setSelCampuses] = useState([]);
    const [selColleges, setSelColleges] = useState([]);
    const [selSchools, setSelSchools] = useState([]);

    // When creating a new one, reset the form because a item can be selected.
    useEffect( () => {
        if (action === CRUD_ACTIONS.CREATE) {
            setEveryone(true);
            setPath('');
            setTitle('');
            setSummary('');
            setBody('');
        }
    }, [action]); // Execute only when action changes.

    const isEditing = action === CRUD_ACTIONS.UPDATE;
    const canUpdatePath = !isEditing;

    const handleSubmitPreview = (e) => {
        e.preventDefault();
        setSendingPreview(true);

        const url = `${config.system.baseApiUrl}/announcements-preview`;
        const data = {
            path: everyone || isEditing ? null : getParams([], [], [], selCampuses, selColleges, selSchools, [], [], '', '', ''),
            term: everyone || isEditing ? {} : { year: selTerm.year, term: selTerm.term },
            title: title === '' ? null : title,
            summary,
            body,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setPreview(res.data.data)
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => setSendingPreview(false));
    };

    const handleSubmit = (e) => {
        const url = `${config.system.baseApiUrl}/announcements${isEditing ? `/${selected.id}` : ''}`;
        const data = {
            path: everyone || isEditing ? null : getParams([], [], [], selCampuses, selColleges, selSchools, [], [], '', '', ''),
            term: everyone || isEditing ? {} : { year: selTerm.year, term: selTerm.term },
            title: title === '' ? null : title,
            summary,
            body,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setSubmitted(true)
                reloadAnnouncements();
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => {
                setPreview();
                setSendingPreview(false);
            });
    };


    /**
     *  Handle selection of term. If the term changes reset the other selections.
     */
    const handleTermChange = ({ value }) => {
        setSelTerm(terms.find(element => element.id === value));
        setSelCampuses([]);
        setSelColleges([]);
        setSelSchools([]);
    };

    const handleGoBackButton = () => {
        setPreview();
    }

    if (action === CRUD_ACTIONS.DELETE) {
        return (
            <>
                <h3>Delete announcement</h3>
                <DeleteModal />
            </>
        );
    }

    if (preview) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new announcement' : 'Edit announcement'} &gt; Preview</h3>
                <PreviewModal announcement={preview.announcement} handleGoBackButton={handleGoBackButton} handleSubmit={handleSubmit} />
            </>
        );
    }

    if (submitted) {
        return (
            <>
                <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new announcement' : 'Edit announcement'} &gt; Preview &gt; Submit</h3>
                <SubmittedModal />
            </>
        );
    }


    return (
        <>
            <h3>{action === CRUD_ACTIONS.CREATE ? 'Create new announcement' : 'Edit announcement'}</h3>
            <form id='interactionForm' onSubmit={handleSubmitPreview}>
                <table>
                    <tbody>
                        <tr>
                            <td align="right">
                                <b>Title:</b>
                                {errors?.errors?.title && <InteractionValidationErrorList errors={errors.errors.title} />}
                            </td>
                            <td>
                                <input type="text" name="title" onChange={(e) => setTitle(e.target.value)} value={title}/>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Summary: *</b>
                                {errors?.errors?.summary && <InteractionValidationErrorList errors={errors.errors.summary} />}
                                <br/>
                                <small>Write the message you want staff to see when they're notified about this announcement.</small>
                            </td>
                            <td>
                                <CharacterLimitedTextBox limit={140} value={summary} onValueChange={setSummary} defaultRows={3}/>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <b>Announcement: *</b>
                                {errors?.errors?.body && <InteractionValidationErrorList errors={errors.errors.body} />}
                            </td>
                            <td>
                                <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        onEditorChange={(value,) => setBody(value)}
                                        value={body}
                                        init={TINYMCE_INIT_SETTINGS}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={'right'}>
                                {errors?.message && <InteractionValidationErrorList errors={[errors.message]} />}
                                <button type="submit" className="primaryButton" disabled={sendingPreview}>
                                    {sendingPreview ? 'Previewing ... ' : 'Preview'}
                                </button>
                                <button type="button" className="secondaryButton" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
}

const PreviewModal = ({ announcement, handleSubmit, handleGoBackButton }) => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = () => {
        setSubmitting(true);
        handleSubmit();
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            <AnnouncementView announcement={announcement} showModifier={false}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" className="primaryButton" onClick={onSubmit} disabled={submitting}>
                                {submitting ? 'Submitting ...' : 'Submit'}
                            </button>
                            <button type="button" className="secondaryButton" onClick={handleGoBackButton} disabled={submitting}>
                                Go Back
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

const SubmittedModal = () => {
    const { handleModal } = useContext(ModalContext);
    return (
        <div>
            <p>
                Successfully submitted the announcement. You may now close this window.
            </p>
            <button type="button" className="primaryButton" onClick={() => handleModal()}>
                Close
            </button>
        </div>
    );
}

const DeleteModal = () => {
    const { selected, reloadAnnouncements } = useContext(AnnouncementContext);
    const { handleModal } = useContext(ModalContext);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        const url = `${config.system.baseApiUrl}/announcements/${selected.id}`;

        axios.delete(url)
            .then(res => {
                setError(null);
                handleModal();
                reloadAnnouncements();
            }).catch(error => {
                setError(error.response.data.errors ?? error);
            });
    }

    if (error) {
        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {error}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>
                            Are you sure you want to permanently delete the selected announcement?
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button type="button" onClick={() => handleModal()}>
                                Cancel
                            </button>
                            <button type="button" className="dangerButton" onClick={handleDelete}>
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default AnnouncementModal;