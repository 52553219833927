import React, { useContext } from 'react';
import { Hint, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { config } from '../../../../utils/config';
import { filterIntegers, renderLegendBar, renderYLabel, withWidget } from '../../../../utils/graph';
import { AssessmentContext } from '../../context/AssessmentContext';
import { truncate, sendLog, getAssessmentExtensionStateColor as getColor } from '../../../../utils/utils';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import { AssetsContext } from '../../../../context/AssetsContext';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';
moment.tz.setDefault('Australia/Brisbane');

const PLOT = {
    width: 300,
    height: 200,
    left: 60,
    bottom: 60,
};

const STATES = [
    'Withdrawn',
    'Revoked',
    'Pending',
    'Denied',
    'Approved',
];

/**
 * Formats extension data for a given assessment and state.
 *
 * @param {Array} extensions - The list of extensions to format
 * @param {string} state - The state for which to format the extensions
 * @param {Array} assessments - The list of assessments for the unit
 * @param {Object} hover - The current hover state of the graph
 * @returns {Array} - The formatted extension data
 */
const getAssessmentExtensionData = (extensions, state, assessments, hover, units) => {
    // create base graph object to force column-ordering
    const data = [];
    assessments.forEach((assessment) => {
        const extension = extensions.find((extension) => {
            return (
                extension.state_code.toUpperCase() === state.toUpperCase() &&
                extension.assessment_id === assessment.id
            );
        });

        if (extension) {
            data.push({
                x: assessment.name,
                y: 1,
                unit_code: units.find( ({id}) => id === assessment.unit_id).code,
                extension_due_date: extension.new_due_date,
                state,
                color: hover.assessment_id === assessment.id && hover.state.toUpperCase() === state.toUpperCase()
                    ? config.colors.cquBlue50
                    : getColor(state),
            });
        }
    });

    return data;
}

/**
 * Renders a legend for the AssessmentExtensionGraph component.
 *
 * @returns {JSX.Element} JSX element containing the legend.
 */
const AssessmentExtensionGraphLegend = () => (
    // Split STATES in reversed order into chunks of three
    <>
        {[...STATES].reverse().reduce((acc, val, idx) => (idx % 3 ? acc[acc.length - 1].push(val) : acc.push([val])) && acc, []).map((chunk, index) => (
            <React.Fragment key={index}>
                {renderLegendBar(
                    chunk.map(state => [state, getColor(state)])
                )}
            </React.Fragment>
        ))}
    </>
)

/**
 *  Displays a graph of assessment extensions for a given unit and enrollments.
 *
 *  @param {Object} props - Component props
 *  @param {Array} props.enrollments - The list of enrollments for the unit
 *  @param {Object} props.hover - The current hover state of the graph
 *  @param {Function} props.handleMouseOver - Function to handle mouse over event
 *  @param {Function} props.handleMouseOut - Function to handle mouse out event
 *
 *  @returns {JSX.Element} JSX element containing the graph.
 */
const AssessmentExtensionGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { isLoading, isError, error, assessments } = useContext(AssessmentContext);
    const {units} = useContext(AssetsContext);

    if (isLoading && !isError) {
        return (
            <div className="UnitWidget">
                <h4>Assessment Extensions<DefinitionInfo code={DefinitionCodes.ASSESSMENT_EXTENSIONS} /></h4>
                <div style={{ marginTop: '40%', textAlign: 'center' }}>Loading ...</div>
            </div>
        );
    }

    if (error) {
        console.log(error);
        return (
            <div className="UnitWidget">
                <h4>Assessment Extensions<DefinitionInfo code={DefinitionCodes.ASSESSMENT_EXTENSIONS} /></h4>
                <div style={{ marginTop: '40%', textAlign: 'center' }}>Could not load data</div>
            </div>
        );
    }

    // Get non-null state_code extensions from assessment and make sure they have a student id which is in enrollments.
    const extensions = sortBy(assessments, ['date_due', 'name']) // Sort the assessments.
        .flatMap(assessment => assessment.extensions) // Get the extensions.
        .filter(extension =>
            STATES.map(state => state.toUpperCase()).includes(extension.state_code?.toUpperCase()) // Must have a valid state.
            && enrollments.find(enrollment => enrollment.id === extension.enrollment_id) // Enrollment id must be in provided enrollments.
        );

    if (!extensions || extensions.length === 0) {
        return (
            <div className="UnitWidget">
                <h4>Assessment Extensions<DefinitionInfo code={DefinitionCodes.ASSESSMENT_EXTENSIONS} /></h4>
                <AssessmentExtensionGraphLegend />
            </div>
        );
    }

    return (
        <div className="UnitWidget">
            <h4>Assessment Extensions<DefinitionInfo code={DefinitionCodes.ASSESSMENT_EXTENSIONS} /></h4>
            <AssessmentExtensionGraphLegend />
            <XYPlot
                height={PLOT.height}
                width={PLOT.width}
                xType="ordinal"
                stackBy="y"
                className={Object.entries(extensions).length === 0 ? "empty" : ""}
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom
                }}
            >
                {hover && (
                    <Hint value={hover} className='plotTooltip'>
                        <div>
                            <h3>{hover.x}</h3>
                            <p>Unit: {hover.unit_code}</p>
                            <p>Status: {hover.state.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</p>
                            <p>Extension due date: {hover.extension_due_date}</p>
                        </div>
                    </Hint>
                )}
                <XAxis tickLabelAngle={-25} tickFormat={x => truncate(x, { len: 20, pos: 'center' })} />
                <YAxis tickFormat={filterIntegers} />
                {renderYLabel('Students')}
                {STATES.map((state, key) =>
                    <VerticalBarSeries
                        key={key}
                        data={getAssessmentExtensionData(extensions, state, assessments, hover, units)}
                        colorType={"literal"}
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                    />)}
            </XYPlot>
        </div>
    );
}

export default withWidget(AssessmentExtensionGraph);