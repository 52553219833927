import { sortBy } from 'lodash';
import React, { useContext } from 'react';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { ModalContext } from '../../../../context/ModalContext';
import { config } from '../../../../utils/config';
import { ASSESSMENT_ATTEMPT_STATUS, ASSESSMENT_DUE_IN_TERM, LOG } from '../../../../utils/constants';
import { renderYLabel, withWidget } from '../../../../utils/graph';
import { sendLog, truncate } from '../../../../utils/utils';
import moment from 'moment-timezone';
import useRouteParams from '../../../../hooks/useRouteParams';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';
import UnitWidgetModal from '../UnitWidgetModal/UnitWidgetModal';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';
moment.tz.setDefault('Australia/Brisbane');

const PLOT = {
    width: 480,
    height: 300,
    left: 80,
    bottom: 80,
    yDomain: [0, 10]
};

const AssessmentSubmissionGraph = ({ unit, assessments, enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    if (!assessments || assessments.length === 0 || !enrollments) {
        return (
            <div className="UnitWidget UnitWidgetWide">
                <h4>Assessment Submissions</h4>
                <div>No data</div>
            </div>
        );
    }

    /**
     * Opens the Modal with the cohort of students.
     */
    const handleClick = (data) => {
        clearAndAddMultipleSelectedEnrollments(enrollments.filter((enr) => data.students.has(enr.student_id)));
        handleModal( <UnitWidgetModal title={`${data.title} - ${data.propDescription}`} handleModal={handleModal} /> );
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit.code,
            aggregation: 'assessmentCalculation',
            value: data.propDescription,
            aggregated: data.students.size,
            assessment_id: data.id,
        });
    }

    const assessmentLabels = {};
    // Each assessment should have a category of 3, on time, too late or not submitted.
    assessments.forEach(assessment => {
        assessment.column_id = assessment.moodle_course_module_id.split('_')[0]; // remove study period from id
        assessmentLabels[assessment.column_id] = assessment.name; // create map of column_id => name for X labels

        assessment.manualSubmissionGraded = new Set(); // Green.
        assessment.manualSubmissionNotGraded = new Set();  // Blue.
        assessment.noDueDate = new Set();  // Blue.
        assessment.overdue = new Set(); // Red.
        assessment.upcoming = new Set(); // Blue.
        assessment.submitted = new Set(); // Green.
        assessment.submittedLate = new Set(); // Orange.
        assessment.submittedNoDue = new Set();  // Green.

        // A assessment can have multiple attempts.
        // A attempt can fall in the on time or too late.
        assessment.attempts.forEach(attempt => {
            // Whatever the attempt is the student must exist in the enrollments.
            if (! enrollments.find( ({student_id}) => student_id === attempt.student_id) ) {
                return;
            }

            // Each enrollment should have a attempt, and has a status which falls in a category.
            if (ASSESSMENT_ATTEMPT_STATUS.MANUAL_SUBMISSION_GRADED.includes(attempt.status)) assessment.manualSubmissionGraded.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.MANUAL_SUBMISSION_NOT_GRADED.includes(attempt.status)) assessment.manualSubmissionNotGraded.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.NO_DUE_DATE.includes(attempt.status)) assessment.noDueDate.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.OVERDUE.includes(attempt.status)) assessment.overdue.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED.includes(attempt.status)) assessment.submitted.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED_NO_DUE.includes(attempt.status)) assessment.submittedNoDue.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED_LATE.includes(attempt.status)) assessment.submittedLate.add(attempt.student_id);
            else if (ASSESSMENT_ATTEMPT_STATUS.UPCOMING.includes(attempt.status)) assessment.upcoming.add(attempt.student_id);
        });
    });

    // Calculate data for graph.
    const sorted = sortBy(assessments, ['date_due', 'name']);

    const dataUpcoming = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.upcoming.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Upcoming',
            date_due: assessment.date_due,
            students: assessment.upcoming,
            color: assessment.id === hover.id && hover.propDescription === 'Upcoming' ? config.colors.cquBlue50 : config.colors.cquBlue80,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataSubmitted = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.submitted.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Submitted',
            date_due: assessment.date_due,
            students: assessment.submitted,
            color: assessment.id === hover.id && hover.propDescription === 'Submitted' ? config.colors.cquBlue50 : config.colors.cquGreen,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataSubmittedNoDueDate = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.submittedNoDue.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Submitted (no Due Date)',
            date_due: assessment.date_due,
            students: assessment.submittedNoDue,
            color: assessment.id === hover.id && hover.propDescription === 'Submitted (no Due Date)' ? config.colors.cquBlue50 : config.colors.cquGreen,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataSubmittedLate = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.submittedLate.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Submitted Late',
            date_due: assessment.date_due,
            students: assessment.submittedLate,
            color: assessment.id === hover.id && hover.propDescription === 'Submitted Late' ? config.colors.cquBlue50 : config.colors.cquOrange,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataOverdue = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.overdue.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Overdue',
            date_due: assessment.date_due,
            students: assessment.overdue,
            color: assessment.id === hover.id && hover.propDescription === 'Overdue' ? config.colors.cquBlue50 : config.colors.cquRed,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dateNoDueDate = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.noDueDate.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'No Due Date',
            date_due: assessment.date_due,
            students: assessment.noDueDate,
            color: assessment.id === hover.id && hover.propDescription === 'No Due Date' ? config.colors.cquBlue50 : config.colors.cquBlue80,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataManualSubmissionGraded = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.manualSubmissionGraded.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Manual Submission (Graded)',
            date_due: assessment.date_due,
            students: assessment.manualSubmissionGraded,
            color: assessment.id === hover.id && hover.propDescription === 'Manual Submission (Graded)' ? config.colors.cquBlue50 : config.colors.cquGreen,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });
    const dataManualSubmissionNotGraded = sorted.map(assessment => {
        return {
            x: assessment.column_id,
            y: assessment.manualSubmissionNotGraded.size,
            id: assessment.id,
            title: assessment.name,
            propName: 'assessmentCalculation',
            propDescription: 'Manual Submission (Not Graded)',
            date_due: assessment.date_due,
            students: assessment.manualSubmissionNotGraded,
            color: assessment.id === hover.id && hover.propDescription === 'Manual Submission (Not Graded)' ? config.colors.cquBlue50 : config.colors.cquBlue80,
            hidden: assessment.hidden ? 'Hidden' : 'Available',
            type: assessment.type,
            dueInTerm: assessment.due_in_term,
        }
    });

    // If there are many assessments double the width.
    const widgetWidth = assessments.length > 10 ? PLOT.width * 2 : PLOT.width;

    return (
        <div className="UnitWidget UnitWidgetAutoWidth">
            <h4>Assessment Submissions<DefinitionInfo code={DefinitionCodes.ASSESSMENT_SUBMISSIONS} /></h4>
            <XYPlot xType="ordinal" height={PLOT.height} width={widgetWidth} xDistance={10} stackBy="y" margin={{ left: PLOT.left, bottom: PLOT.bottom }}>
                {hover && (
                    <Hint value={hover} className='plotTooltip'>
                        <div>
                            <h3>{hover.title}</h3>
                            <p>Group: {hover.propDescription}</p>
                            <p>Due Date: {
                                hover.due_in_term === ASSESSMENT_DUE_IN_TERM.NO_DUE_DATE || hover.date_due === '1970-01-01 10:00:00'
                                    ? 'None'
                                    : moment(hover.date_due).format('MMMM Do YYYY, h:mm:ss a')
                            }</p>
                            <p>Students: {hover.students.size}</p>
                            <p>Assessment Method: {hover.type}</p>
                            <p>Status: {hover.hidden}</p>
                        </div>
                    </Hint>
                )}
                <HorizontalGridLines />
                <XAxis height={50} tickLabelAngle={-25} tickFormat={x => truncate(assessmentLabels[x], { len: 20, pos: 'center' })} />
                <YAxis />
                {renderYLabel('Students')}
                {[dataUpcoming,dataSubmitted,dataSubmittedNoDueDate,dataSubmittedLate,dataOverdue,dateNoDueDate,dataManualSubmissionGraded,dataManualSubmissionNotGraded].map( (data, key) =>
                    <VerticalBarSeries
                        key={key}
                        data={data}
                        className={"clickable"}
                        colorType={"literal"}
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                        onValueClick={handleClick}
                    />
                )}
            </XYPlot>
        </div>
    );
};

export default withWidget(AssessmentSubmissionGraph);
