import React from 'react';
import { filterIntegers, renderXLabel, renderYLabel, withWidget } from '../../../../utils/graph';
import { config } from '../../../../utils/config';
import { Hint, XAxis, XYPlot, YAxis } from 'react-vis';
import { make_object } from '../../../../utils/utils';
import { VerticalGridLines, HorizontalBarSeries } from 'react-vis';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';

const PLOT = {
    width: 300,
    height: 280,
    left: 90,
    bottom: 60,
};

const getColor = type => {
    let color = config.colors.note;
    if (type === 'Meeting' || type === 'Call') {
        color = config.colors.meeting;
    } else if (type === 'Email' || type === 'Mailout') {
        color = config.colors.mailout;
    } else if (type === 'Crm') {
        color = config.colors.crm;
    }
    return color;
}

const InteractionSummaryGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {

    const interactionsSent = enrollments.flatMap(enrollment => enrollment.interactionsSent) || [];

    const totalsObj = interactionsSent.reduce((acc, item) => {
        acc[item.interaction.interaction_type] = acc[item.interaction.interaction_type] || 0;
        acc[item.interaction.interaction_type]++;
        return acc;
    }, make_object(config.notesExceptCrm, 0));

    //  Translate data for graphing
    const data = Object.entries(totalsObj).map((parts) => {
        const [interaction_type, total] = parts;
        return {
            name: interaction_type,
            code: interaction_type,
            total: total
        };
    });

    const graph = data.map((item) => {
        return {
            y: item.code,
            x: item.total,
            color: getColor(item.code),
            code: item.code,
            label: item.name,
            description: item.name,
        };
    });

    return (
        <div className="UnitWidget">
            <div style={{ float: 'right' }}>Σ = {interactionsSent.length}</div>
            <h4>Interactions Summary<DefinitionInfo code={DefinitionCodes.INTERACTIONS} /></h4>
            <XYPlot
                height={PLOT.height}
                width={PLOT.width}
                yType="ordinal"
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.description}</h3>
                            <p>{hover.x}</p>
                        </div>
                    </Hint>
                )}
                <XAxis tickFormat={filterIntegers}/>
                <YAxis width={PLOT.left} />
                {renderXLabel("Count of Interactions", PLOT)}
                {renderYLabel("Interactions", PLOT)}
                <VerticalGridLines />
                <HorizontalBarSeries
                    colorType={"literal"}
                    data={graph}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
        </div>
    );
}

export default withWidget(InteractionSummaryGraph);