import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { AssetsContext } from '../../../../context/AssetsContext';
import { mapBeginDateToShortDates, mapBeginDateToWeeks } from '../../../../utils/calendar';
import { config } from '../../../../utils/config';
import { filterIntegers, withWidget, renderYLabel, renderXLabel } from '../../../../utils/graph';
import { AssessmentContext } from '../../context/AssessmentContext';
import moment from 'moment-timezone';
import { ASSESSMENT_ATTEMPT_STATUS, ASSESSMENT_DUE_IN_TERM } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';
moment.tz.setDefault('Australia/Brisbane');

/**
 * Function to build/format acc object for display in graph.
 *
 * @param {String} start - A string representing the start week for the graph.
 * @param {Object} ass - An assessment object containg information about assessment.
 * @param {Object} enrollment - An object representing the enrollment containg student information for assessment attempts.
 * @returns {Object} - An acc object for adding to graph.
 */
const buildAcc = (start, ass, enrollment) => {
    const attempt = ass.attempts.find(att => att.student_id === enrollment.student_id);
    let color = config.colors.cquBlue50;
    let submitted = 'Not submitted';

    if (!attempt) {
        // Not submitted.
        color = config.colors.cquBlue80;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.NO_DUE_DATE.includes(attempt.status)) {
        color = config.colors.cquBlue80;
        submitted = 'No Due Date';
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.MANUAL_SUBMISSION_GRADED.includes(attempt.status)) {
        color = config.colors.cquGreen;
        submitted = `Manual Submission (Graded)`;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.MANUAL_SUBMISSION_NOT_GRADED.includes(attempt.status)) {
        color = config.colors.cquBlue80;
        submitted = `Manual Submission (Not Graded)`;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.OVERDUE.includes(attempt.status)) {
        color = config.colors.cquRed;
        submitted = 'Overdue';
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED.includes(attempt.status)) {
        color = config.colors.cquGreen;
        submitted = `Submitted on: ${moment(attempt.date_attempted).format('LLL')}`;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED_NO_DUE.includes(attempt.status)) {
        color = config.colors.cquGreen;
        submitted = `Submitted (no Due Date) on: ${moment(attempt.date_attempted).format('LLL')}`;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.SUBMITTED_LATE.includes(attempt.status)) {
        color = config.colors.cquOrange;
        submitted = `Submitted Late on: ${moment(attempt.date_attempted).format('LLL')}`;
    }
    else if (ASSESSMENT_ATTEMPT_STATUS.UPCOMING.includes(attempt.status)) {
        color = config.colors.cquBlue80;
        submitted = 'Upcoming';
    }

    const due_date = ass.due_in_term === ASSESSMENT_DUE_IN_TERM.NO_DUE_DATE || ass.date_due === '1970-01-01 10:00:00'
        ? 'None'
        : moment(ass.date_due).format('LLL');

    return {
        x: start,
        y: 1,
        code: ass.id,
        name: ass.name,
        due: due_date,
        submitted: submitted,
        cluster: enrollment.unit.code,
        color: color,
        hidden: ass.hidden ? 'Hidden' : 'Available',
        type: ass.type
    };
}

/**
 * This component displays a bar graph of assessments by week of term for a set of enrollments.
 * It uses the react-vis library for rendering the graph.
 *
 * @param {Array} enrollments - An array of enrollment objects containing information about the units and students.
 * @param {Object} hover - An object representing the assessment that is currently being hovered over by the user.
 * @param {Function} handleMouseOver - A function to handle mouseover events on the graph.
 * @param {Function} handleMouseOut - A function to handle mouseout events on the graph.
 * @returns {JSX.Element} - A JSX element representing the assessment graph.
 */
const AssessmentsGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { year, term } = useRouteParams();
    const { termInfo } = useContext(AssetsContext);
    const { assessments } = useContext(AssessmentContext);
    const isVet = term === 'VET';

    const unitIds = enrollments.map(enr => enr.unit_id);
    const thisTerm = termInfo.displayedTerm;

    // Filter the assessments so that they are only for the targeted enrollments,
    // then filter again to get the assessments that are due during and after this term.
    const assessmentsDue = assessments.filter(ass => unitIds.includes(ass.unit_id))

    // Map assessments by week and index by unit code.
    const result = enrollments.reduce((acc, enrollment) => {
        const unitCode = enrollment.unit.code;
        const assessmentsInEnrollment = assessmentsDue.filter(ass => ass.unit_id === enrollment.unit_id);
        const assessmentsDueInTerm = assessmentsInEnrollment.filter(ass => ass.due_in_term === ASSESSMENT_DUE_IN_TERM.DUE_IN_TERM);
        const assessmentsNoDueDate = assessmentsInEnrollment.filter(ass =>ass.due_in_term === ASSESSMENT_DUE_IN_TERM.NO_DUE_DATE);
        const assessmentsNotDueInTerm = assessmentsInEnrollment.filter(ass => ass.due_in_term === ASSESSMENT_DUE_IN_TERM.NOT_DUE_IN_TERM);

        if (!acc[unitCode]) {
            acc[unitCode] = [];
        }

        // Add assessments by week.
        [...thisTerm.weeks.data].forEach((week) => {
            const { start } = week;
            const assessmentsInWeek = assessmentsDueInTerm.filter(ass => (
                moment(week.start).isSameOrBefore(ass.date_due) &&
                moment(week.end).isSameOrAfter(ass.date_due))
            );

            if (assessmentsInWeek.length === 0) {
                acc[unitCode].push({
                    x: start,
                    y: 0,
                });
            }

            assessmentsInWeek.forEach(ass => {
                acc[unitCode].push(buildAcc(start, ass, enrollment));
            });
        });

        // Add No Due Date assessments.
        if (assessmentsNoDueDate.length === 0) {
            acc[unitCode].push({
                x: '1970-01-01 00:00:00',
                y: 0,
            });
        }
        assessmentsNoDueDate.forEach(ass => {
            acc[unitCode].push(buildAcc('1970-01-01 00:00:00', ass, enrollment));
        });

        // Add Not Due In Term assessments.
        if (assessmentsNotDueInTerm.length === 0) {
            acc[unitCode].push({
                x: thisTerm.end,
                y: 0,
            });
        }
        assessmentsNotDueInTerm.forEach(ass => {
            acc[unitCode].push(buildAcc(thisTerm.end, ass, enrollment));
        });

        return acc;
    }, {});

    const PLOT = {
        height: isVet ? 400 : 280,
        width: isVet ? 1050 : 960,
        left: isVet ? 80 : 60,
        bottom: isVet ? 80 : 50,
    };

    return (
        <div className={isVet ? 'UnitWidget UnitWidgetWide VetUnitWidgetWide' : 'UnitWidget UnitWidgetAutoWidth'}>
            <h4>Assessments by Week of Term<DefinitionInfo code={DefinitionCodes.ASSESSMENTS} /></h4>
            <XYPlot
                xType="ordinal"
                height={PLOT.height}
                width={PLOT.width}
                xDistance={10}
                stackBy="y"
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom,
                }}
            >
                {hover && <Hint value={hover} className='plotTooltip'>
                    <div>
                        <h3>{hover.name}</h3>
                        <p>Due Date: {hover.due}</p>
                        <p>Group: {hover.submitted}</p>
                        <p>Unit: {hover.cluster}</p>
                        <p>Assessment Method: {hover.type}</p>
                        <p>Status: {hover.hidden}</p>

                    </div>
                </Hint>}
                {isVet ? <XAxis height={50} tickFormat={val => mapBeginDateToShortDates(val, thisTerm.weeks.data)} tickLabelAngle={-45} />
                    : <XAxis height={50} tickFormat={val => mapBeginDateToWeeks(val, thisTerm.weeks.data)} tickLabelAngle={-25} />}
                <YAxis width={isVet ? 80 : 60} tickFormat={filterIntegers} />
                <HorizontalGridLines />
                {renderXLabel('Weeks', PLOT)}
                {renderYLabel('Total assessments', PLOT)}
                {Object.entries(result).map(([key, value]) =>
                    <VerticalBarSeries
                        key={key}
                        data={value}
                        cluster={isVet ? null : key}
                        stroke="#ffffff"
                        colorType={'literal'}
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                    />
                )}
            </XYPlot>
        </div>
    );
};

export default withWidget(AssessmentsGraph);
