import { scale } from "chroma-js";
import _, { chunk, isEqual, capitalize as capitalizeText } from "lodash";
import Cookies from "js-cookie";
import { config } from "./config"
import axios from "axios";
import { CRM_CREATION_USERS_TO_REPLACE, CURRENT_VET_YEAR_VALUE, PASS_RATE_NULL } from "./constants";
import { faCircle, faCube, faEnvelope, faMailBulk, faStickyNote, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

//  [NC 2019-01-18]
//  General utility functions.

function cmp(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 1;
}

function divide(list, n) {
  const len = Math.ceil(list.length / Math.abs(n));
  let parts = [];
  for (let step = 0; step < list.length; step += len) {
    parts.push(list.slice(step, step + len))
  }
  return parts;
}

function range(start, end) {
  //  range(1, 5) => [1,2,3,4,5]  // <-- Inclusive
  return [...Array(end - start + 1).keys()].map(i => start + i);
}

function alphabet_divisions(total, maximum) {
  //  Useful for cutting a 2,000 student cohort down to groups of 300 at most;
  //  adjust for actual student ditributions, incl. international.
  const ratio = total / maximum;
  if (ratio < 1) return ["A-Z"];
  if (ratio < 2) return ["A-J", "K-Z"];
  if (ratio < 3) return ["A-G", "H-M", "N-Z"];
  if (ratio < 4) return ["A-D", "E-J", "K-Q", "R-Z"];
  if (ratio < 5) return ["A-C", "D-G", "H-K", "L-P", "Q-Z"];
  if (ratio < 6) return ["A-C", "D-F", "G-J", "K-M", "N-R", "S-Z"];
  if (ratio < 7) return ["A-B", "C-D", "E-I", "J-K", "L-M", "N-R", "S-Z"];
  if (ratio < 8) return ["A-B", "C-D", "E-H", "I-J", "K-L", "M", "N-R", "S-Z"];
  return ["A", "B-C", "D", "E-I", "J", "K-L", "M-N", "O-R", "S", "T-Z"];
}

function calc_percentage(num, denom) {
  return denom === 0
    ? 0
    : Math.round((num * 100) / denom);
}

function color_scheme(keys, colors) {
  //  console.log(keys);
  //  Return a hash of keys mapped to steps along a color gradient.
  const gradient = scale(colors).mode("lab");
  const steps = gradient.colors(keys.length);
  let scheme = {};
  for (const i in keys) {
    scheme[keys[i]] = steps[i];
  }
  return scheme;
}

function days_ago(time, now = null) {
  if (time === null) {
    return null;
  }
  now = (now === null) ? moment() : moment(now);
  const then = moment(time);
  return Math.floor(now.diff(then, 'days'));
}


function make_object(keys, defaultValue = 0) {
  return keys.reduce((acc, key) => {
    acc[key] = defaultValue;
    return acc;
  }, {});
}

function name_initials(str, defaultValue = "") {
  const words = str.split(/\W+/).map(_ => _.trim()).filter(_ => _ !== "");
  if (words.length === 0) {
    return defaultValue;
  } else if (words.length === 1) {
    const first = words[0] || "";
    return first[0] || "";
  } else {
    const first = words[0] || "";
    const last = words[words.length - 1] || "";
    return (first[0] || "") + (last[0] || "");
  }
}

//  if (str.length === 0) {
//  return "_";
//  }
//  let words = str.split(" ");
//  if (words.length === 1) {
//  return words[0][0];
//  } else {
//  return words[0][0] + words[words.length - 1][0];
//  }
//  }

function sort_by_weight(list, listWeights) {
  //  (['A', 'B', 'C'], Map {A: 1, B: 3, C: 2}) -> ['B', 'C', 'A']
  //  Heaviest first, so note REVERSE sorting.
  return list.sort((a, b) => {
    if (listWeights.get(a) < listWeights.get(b)) return 1;
    if (listWeights.get(a) > listWeights.get(b)) return -1;
    return 0;
  });
}

function calc_weighted_score(list, listWeights) {
  //  (['A', 'B', 'C', 'C'], Map {A: 1, B: 3, C: 2}) -> 8
  return list.reduce((acc, item) => (acc += listWeights.get(item)), 0);
}

//  -------------------------------------
//  Success functions
//  -------------------------------------

function get_success(percent, config, values = ['low', 'medium', 'high']) {
  //  console.log(percent, config, values);
  //  [NC 2019-02-04] This needs to take thresholds as config arguments.
  if (percent === null) {
    return 'null';
  }
  let percentInt = parseInt(percent);
  if (isNaN(percentInt)) {
    return 'default';
  }
  if (percentInt < config.low) {
    return values[0];
  } else if (percentInt <= config.high) {
    return values[1];
  } else {
    return values[2];
  }
}

function getPassRateGroup(value, config = { low: 50, high: 75 }) {
  if (value === null) {
    return '-'
  }

  if (value === '-') {
    return '-'
  }

  const integerPassRate = parseInt(value);

  if (isNaN(integerPassRate)) {
    return '-'
  }

  if (integerPassRate < config.low) {
    return 'Low';
  } else if (integerPassRate < config.high) {
    return 'Medium';
  }

  return 'High';
}

function get_Success(percent, config) {
  return get_success(percent, config, ['Low', 'Medium', 'High']);
}

function get_success_rank(percent, config) {
  return get_success(percent, config, [1, 2, 3]);
}

function bg_class(percent, config) {
  let grade = get_success(percent, config);
  return "bg-" + grade;
}

function fg_class(percent, config) {
  let grade = get_success(percent, config);
  return "fg-" + grade;
}

function calc_unit_score(units, config) {
  //  [high, high, med, low] -> 332100
  //  [high] -> 300000
  const sorted = sort_units(units);
  let factor = 10 ** 6; // Assume max 6 units; ignore more.
  let score = 0;
  sorted.forEach(unit => {
    let rank = get_success_rank(unit.prediction, config);
    score += rank * factor;
    factor /= 10;
  });
  return score;
}

function sort_units(list) {
  return list.sort((a, b) => {
    if (a["prediction"] < b["prediction"]) return -1;
    if (a["prediction"] > b["prediction"]) return 1;
    return 0;
  });
}

function get_grade(percent) {
  //  This uses Australia's federal grading, and may vary with state or institution.
  let percentInt = parseInt(percent);
  if (isNaN(percentInt)) {
    return 'undefined';
  }
  if (percentInt < 50) {
    return "F";
  } else if (percentInt < 60) {
    return "D";
  } else if (percentInt < 70) {
    return "C";
  } else if (percentInt < 80) {
    return "B";
  } else if (percentInt < 90) {
    return "A";
  } else {
    return "A+";
  }
}

function minutes(number) {
  const sign = Math.sign(number);
  const signMarker = sign === -1 ? "-" : "";
  const absolute = Math.abs(number);
  const hours = Math.floor(absolute / 60);
  const mins = absolute % 60;
  const zero = mins < 10 ? "0" : "";
  return `${signMarker}${hours}:${zero}${mins}`;
}

function pluralize(number, singular, plural = null) {
  if (number === null || number === undefined) {
    return '--';
  }
  if (number === 1) {
    return number.toString() + " " + singular;
  } else if (plural === null) {
    return number.toString() + " " + singular + "s";
  } else {
    return number.toString() + " " + plural;
  }
}

function grammatical_join(items, and_or) {
  if (items.length < 2) {
    return items.join(' ');  // <-- "" or first term.
  } else {
    const body = items.slice(0, items.length - 1);
    const foot = items[items.length - 1];
    return [body.join(', '), and_or, foot].join(" ");
  }
}

function chunk_square(array, number) {
  // chunk array to a given number
  //
  // automatically reduce number to prevent orphan elements in final chunk
  // except when input length reaches number^2
  const len = array.length;
  if (len <= number || len >= Math.pow(number, 2)) {
    return chunk(array, number);
  }
  let bestChunk;
  let bestRem;
  for (let i = number; i >= (number / 2); i--) {
    let iRem = len % i;
    if (bestRem === undefined || iRem > bestRem || iRem === 0) {
      bestChunk = i;
      bestRem = iRem;
      if (bestRem === 0) {
        break;
      }
    }
  }
  return chunk(array, bestChunk);
}

function hide_string(str, len = 0) {
  len = len || String(str).length;
  return new Array(len + 1).join('X');
}

// perform deep equality check
function deep_equal(a, b) {
  return isEqual(a, b);
}

function read_snooze_cookie() {
  const cookie_str = Cookies.get('snooze-notifications');
  if (cookie_str !== undefined) {
    const snooze_expires = moment(cookie_str, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    if (snooze_expires.isValid()) {
      return snooze_expires;
    }
  }
  return null;
}

function test_snooze_cookie(snooze) {
  if (moment.isMoment(snooze)) {
    if (snooze.isAfter(moment())) {
      return true;
    }
  }
  return false;
}

function is_function(fn) {
  return fn && {}.toString.call(fn) === '[object Function]';
}

/**
 * Truncates `string` if it's longer than the given maximum string length.
 * The first, center or last characters of the truncated string are replaced with the omission
 * string which defaults to "...".
 *
 * @param {string} [str=''] The string to truncate.
 * @param {Object} [options={}] The options object.
 * @param {number} [options.len=60] The maximum string length.
 * @param {string} [options.omission='...'] The string to indicate text is omitted.
 * @param {string} [options.pos='end'] Where to trunacate the strin start | center | end.
 * @returns {string} Returns the truncated string.
 */
function truncate(str, options) {
  // Set the option values to constants or towards their default values.
  const len = options?.len ?? 60,
    omission = options?.omission?.toString() ?? '...',
    pos = options?.pos ?? 'end',
    chrToShow = len - omission.length;

  // Ensure str is string
  str = str.toString();

  // If the string's length is smaller than the given maxLength then return it.
  if (str.length <= len) return str;

  // If the len parameter is smaller then 0 return the string and log an error.
  // Also we check if it's not a number.
  if (isNaN(len) || len <= 0) {
    console.error("The given value for parameter len is incorrect. It should be of type number and a value larger then 0. The given value was: " + len);
    return str;
  }

  // If the end is smaller one then return the omission.
  if (chrToShow < 1) return omission;

  switch (pos.toLowerCase()) {
    case 'start':
      return omission + str.substr(str.length - len + omission.length);
    case 'center':
      const startChr = Math.ceil(chrToShow / 2),
        endChrs = Math.floor(chrToShow / 2);
      return str.substr(0, startChr) + omission + str.substr(str.length - endChrs);
    // Default case would be 'end'.
    default:
      return str.substr(0, chrToShow) + omission;
  }
}

/**
 * This function is used for the .sort() method. This will alphabetically order items in a expected behaviour.
 *
 * Example:
 *
 * Given the array of the following items:
 *
 * ['', undefined, 10, 1, 'hello', .1, 'Zulu', null]
 *
 * items.sort(alphabetical);
 * // ["", 10, 1, "hello", 0.1, "Zulu", null, undefined]
 *
 * items.sort(alphabetical(true));
 * // ["Zulu", "hello", 10, 1, 0.1, "", null, undefined]
 *
 * @param {boolean} descending - default value is false
 * @returns
 */
function alphabetical(descending = false, property = null) {
  const SortCollator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
  if (property === null) {
    return function (a, b) {
      if (a === b) {
        return 0;
      }
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }

      if (a.toString().trim() === '') {
        return 1;
      }
      if (b.toString().trim() === '') {
        return -1;
      }

      let ret = SortCollator.compare(a, b)

      if (descending) {
        ret = -ret
      }

      return ret
    };
  }

  return function (a, b) {
    if (a[property] === b[property]) {
      return 0;
    }
    if (a[property] === null) {
      return 1;
    }
    if (b[property] === null) {
      return -1;
    }

    if (a[property].toString().trim() === '') {
      return 1;
    }
    if (b[property].toString().trim() === '') {
      return -1;
    }

    let ret = SortCollator.compare(a[property], b[property])

    if (descending) {
      ret = -ret
    }

    return ret
  };

}

/**
 * Integer to distinguish one browser tab from another.
 *
 * Used in event_logs.other and InteractionForm,
 * for matching selection and interaction created events when the user switches between tabs.
 */
const tabId = Date.now();

/**
 * Sent logging to backend in able to do usage tracking.
 *
 * @param {string} name The name of the event which can be easily found in backend for example, App\Event\Cohort\Filtered
 * @param {string} crud What type of operation: 'c', 'r', 'u', 'd'
 * @param {string} action The action what was done, usually one or two words: viewed, filtered, updated, logged in
 * @param {string} target The target of the log: user, page, enrolment, student, config
 * @param {string} dashboard The dashboard/page where the event is trigged
 * @param {dict} other Any addition data
 */
function sendLog(name, crud, action, target, dashboard = null, other = null) {
  if ((name === "App\\Events\\Cohort\\Selected" || name === "App\\Events\\Cohort\\Discarded") && other.path === null) {
    return;
  }

  const log = { name, crud, action, target, dashboard, other: {...other, tabId} };
  axios.post([config.system.baseApiUrl, 'eventlog'].join('/'), log)
    .catch(res => console.warn('Log failed with data', log));
}

/**
 * Check if the keys and values of two objects are the same.
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {boolean}
 */
function haveSameData(obj1, obj2) {
  return _.isEqual(obj1, obj2)
}

/**
 * Check if the params object have the same filters.
 *
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {boolean}
 */
function haveSameFilters(obj1, obj2) {
  return haveSameData(getFiltersFromParams(obj1), getFiltersFromParams(obj2));
}

/**
 * Get the filters only from params. We do this by removing order and path. Also we remove empty properties.
 * @param {Object} paramsObj
 * @returns {boolean}
 */
function getFiltersFromParams(paramsObj) {
  let obj = { ...paramsObj };
  delete obj.order;
  delete obj.path;
  delete obj.sort;
  Object.keys(obj).forEach(function (key) {
    if (obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
}

/**
 * Some URLs from the API are malformed and lack the '#Cases' part.
 * WRONG: https://sugarcrm2-test.cqu.edu.au/6be10e06-9d2a-11e9-bcb3-0050568e1f75
 * RIGHT: https://sugarcrm2-test.cqu.edu.au/#Cases/6be10e06-9d2a-11e9-bcb3-0050568e1f75
 *
 * @param {string} url
 * @returns
 */
function message_crm_url(url) {
  if (url.includes('#Cases')) {
    return url;
  } else {
    let parts = url.split('/');
    const uuid = parts.pop();
    parts.push('#Cases');
    parts.push(uuid);
    const new_url = parts.join('/');
    return new_url;
  }
}

/**
 * Create the parameters for the url.
 *
 * @param {Array} units
 * @param {Array} students
 * @param {Array} courses
 * @param {Array} campuses
 * @param {Array} colleges
 * @param {Array} schools
 * @param {Array} maps
 * @param {Array} flags
 * @param {Array} ages
 * @param {Array} lastLogins
 * @param {Array} enrSince
 *
 * @returns {string}
 */
function getParams(units, courses, students, campuses, colleges, schools, maps, flags, ages, lastLogins, enrSince) {
  // Create the parameters.
  const parts = [];

  if (Array.isArray(units)) {
    units.forEach(({ value }) => parts.push(`:${value}`));
  }
  if (Array.isArray(courses)) {
    courses.forEach(({ value }) => parts.push(`!${value}`));
  }
  if (Array.isArray(students)) {
    students.forEach(({ value }) => parts.push(`~${value}`));
  }
  if (Array.isArray(campuses)) {
    campuses.forEach(({ value }) => parts.push(`@${value}`));
  }
  if (Array.isArray(colleges)) {
    colleges.forEach(({ value }) => parts.push(`.${value}`));
  }
  if (Array.isArray(schools)) {
    schools.forEach(({ value }) => parts.push(`^${value}`));
  }
  if (Array.isArray(maps)) {
    maps.forEach(({ value }) => parts.push(`*${value}`));
  }
  if (Array.isArray(flags)) {
    flags.forEach(({ value }) => parts.push(`*${value}`));
  }

  if (ages !== undefined && ages.trim() !== '') {
    ages.trim().split(',').forEach(value => parts.push(`${value}y`));
  }
  if (lastLogins !== undefined && lastLogins.trim() !== '') {
    lastLogins.trim().split(',').forEach(value => parts.push(`${value}d`));
  }
  if (enrSince !== undefined && enrSince.trim() !== '') {
    enrSince.trim().split(',').forEach(value => parts.push(`${value}e`));
  }

  const joined = parts.join(',');

  if (joined === '') {
    return '';
  }

  return `&path=${joined}`;
}

/**
 * Capitalize a string.
 *
 * @param {string} text
 * @returns {string}
 */
function capitalize(text) {
  return capitalizeText(text);
}

/**
 * Callback function for filtering elements. Using recursion to target deeper levels of a enrollment record.
 *
 * @param {string} target  Which property to target. Example: 'id' or 'student.flags.*.flag_id'
 * @param {Array} values
 *
 * @returns {boolean}
 */
function filterByProperty(target, values, element) {
  const keys = Object.keys(element);
  const propName = target.split('.').splice(0, 1).shift(); // Get the element 'foo' of string 'foo.goo.bar'.
  const lastPropName = target.split('.').reverse().splice(0, 1).shift();
  const nextTarget = target.split('.').splice(1).join('.');

  // When the target is against a array.
  if (propName === '*' && Array.isArray(element)) {
    if (propName === lastPropName) {
      console.error("Array indicator '*' is not a valid target.");
      return false;
    }
    // Some means that at least one item of the array must return true.
    return element.some((item) => filterByProperty(nextTarget, values, item));
  }

  if (!keys.includes(propName)) {
    console.error("Could not apply filter. Unknown attribute.")
    return false;
  }

  // Check if the last element 'bar' matches first element 'foo' for string 'foo.goo.bar'.
  // If they do not match we have to go one level deeper.
  if (propName !== lastPropName) {
    // Recursion at passing target at 'goo.bar' from 'foo.goo.bar'.
    return filterByProperty(nextTarget, values, element[propName]);
  }

  // When the final target is a array at least one has to match.
  if (Array.isArray(element[propName])) {
    return values.some(item => element[propName].includes(item));
  }

  if (element[propName] === null || element[propName] === undefined) {
    return false;
  }

  return values.includes(element[propName].toString());
}

/**
 * This gets a unique set of strings from a property. Using recursion to target deeper levels of a enrollment record.
 *
 * @param {string} target  Which property to target. Example: 'id' or 'student.flags.*.flag_id'
 * @param {Array} enrollments
 *
 * @returns {mixed}
 */
function getValueByProperty(target, element) {
  const keys = Object.keys(element);
  const propName = target.split('.').splice(0, 1).shift(); // Get the element 'foo' of string 'foo.goo.bar'.
  const lastPropName = target.split('.').reverse().splice(0, 1).shift(); // Get the element 'bar' of string above.
  const nextTarget = target.split('.').splice(1).join('.'); // Get the element 'goo' of string above.

  // When the target is against a array.
  if (propName === '*' && Array.isArray(element)) {
    if (propName === lastPropName) {
      console.error("Array indicator '*' is not a valid target.");
      return undefined;
    }
    // Some means that at least one item of the array must return true.
    return element.map((item) => getValueByProperty(nextTarget, item));
  }

  if (!keys.includes(propName)) {
    return undefined;
  }

  // Check if the last element 'bar' matches first element 'foo' for string 'foo.goo.bar'.
  // If they do not match we have to go one level deeper.
  if (propName !== lastPropName) {
    // Recursion at passing target at 'goo.bar' from 'foo.goo.bar'.
    return getValueByProperty(nextTarget, element[propName]);
  }

  return element[propName];
}

/**
 * This sets a value for a property. Using recursion to target deeper levels of an object.
 *
 * @param {string} target  Which property to target. Example: 'id' or 'student.flags.*.flag_id'
 * @param {mixed} value The value to set the target to.
 * @param {object} element The object to modify.
 *
 * @returns {boolean} True if the value was successfully set, false otherwise.
 */
function setValueByProperty(target, value, element) {
  const keys = Object.keys(element);
  const propName = target.split('.').splice(0, 1).shift(); // Get the element 'foo' of string 'foo.goo.bar'.
  const lastPropName = target.split('.').reverse().splice(0, 1).shift(); // Get the element 'bar' of string above.
  const nextTarget = target.split('.').splice(1).join('.'); // Get the element 'goo.bar' of string above.

  // When the target is against an array.
  if (propName === '*' && Array.isArray(element)) {
    if (propName === lastPropName) {
      console.error("Array indicator '*' is not a valid target.");
      return false;
    }
    // Some means that at least one item of the array must return true.
    return element.map((item) => setValueByProperty(nextTarget, value, item));
  }

  // If the propName doesn't exist and it's not a wildcard, add it to the object with the value
  if (!keys.includes(propName) && propName !== '*') {
    element[propName] = {};
  }

  // Check if the last element 'bar' matches first element 'foo' for string 'foo.goo.bar'.
  // If they do not match we have to go one level deeper.
  if (propName !== lastPropName) {
    // Recursion at passing target at 'goo.bar' from 'foo.goo.bar'.
    return setValueByProperty(nextTarget, value, element[propName]);
  }

  element[propName] = value;
  return true;
}

/**
 * Calculates the pass rate.
 * @param {number} attempted
 * @param {number} passed
 *
 * @returns {number}
 */
function calcPassRate(attempted, passed) {
  if (attempted <= 0) {
    return PASS_RATE_NULL;
  }

  return Number((passed * 100) / attempted).toFixed();
}

/**
 * Get preference from a user object, returns a objects containing filter and columns.
 *
 * @param {Object} user The user stores the preference.
 * @param {boolean} isCurrent Indicates which preference category to return.
 * @param {boolean} isVet Indicates which preference category to return.
 * @return {Object}
 */
function getPreferenceFromUser(user, isCurrent, isVet) {
  const category = isCurrent ? 'current' : 'historic';
  const subcategory = isVet ? 'VET' : 'HE';

  const defaultColumns = config.defaultPrefs[category][subcategory].default.columns;
  const defaultFilters = config.defaultPrefs[category][subcategory].default.filters;

  const unavailableColumns = config.defaultPrefs[category][subcategory].unavailable.columns;
  const unavailableFilters = config.defaultPrefs[category][subcategory].unavailable.filters;

  const selectedColumns = user.preference && user.preference[category] && user.preference[category][subcategory] ? user.preference[category][subcategory].columns : [];
  const selectedFilters = user.preference && user.preference[category] && user.preference[category][subcategory] ? user.preference[category][subcategory].filters : [];

  const columns = selectedColumns.length > 0 ? selectedColumns : defaultColumns;
  const filters = selectedFilters.length > 0 ? selectedFilters : defaultFilters;

  const sanitizedColumns = columns.filter(column => config.columnOptions.includes(column) && !unavailableColumns.includes(column));
  const sanitizedFilters = filters.filter(filter => config.filterOptions.includes(filter) && !unavailableFilters.includes(filter));

  return { columns: sanitizedColumns, filters: sanitizedFilters };
}

/**
 * Returns the appropriate font awesome icon based on the given type.
 * @param {string} type - The type of content.
 * @returns {string} The font awesome icon associated with the given type.
 */
function getTypeIcon(type) {
  const sanitized = type.toLowerCase();
  if (sanitized === 'mailout') return faMailBulk;
  if (sanitized === 'email') return faEnvelope;
  if (sanitized === 'note') return faStickyNote;
  if (sanitized === 'crm') return faCube;
  return faCircle;
}

/**
 * Given a term or week object, determine if this is current.
 * This will be true if the current timestamp is between the start and end timestamps of the object.
 *
 * @param {Object} term or week
 * @return {boolean}
 */
function isCurrent(period) {
  if(!period) {
    return false;
  }
  if (!Object.keys(period).includes('start') || !Object.keys(period).includes('end')) {
    return false;
  }
  return moment().isSameOrAfter(period.start) && moment().isSameOrBefore(period.end);
}

/**
 * Given a term determine if this is current. The VET terms are only current if the year matches CURRENT_VET_YEAR_VALUE.
 *
 * @param {Object} term or week
 * @return {boolean}
 */
function isCurrentTerm(term) {
  if (term.term === 'VET') {
    return CURRENT_VET_YEAR_VALUE === term.year;
  }

  return isCurrent(term);
}

/**
 * Gets the color for a given extension state.
 *
 * @param {string} state - The state for which to get the color
 * @returns {string} - The color for the state
 */
const getAssessmentExtensionStateColor = (state) => {
  if (state.toUpperCase() === "APPROVED") return config.colors.cquGreen;
  if (state.toUpperCase() === "DENIED") return config.colors.cquRed;
  if (state.toUpperCase() === "PENDING") return config.colors.cquLightBlue;
  if (state.toUpperCase() === "REVOKED") return config.colors.cquLightCharcoal;

  // WITHDRAWN or anything else.
  return config.colors.cquBlue;
}

/**
 * Submits the user's preferences to the server.
 * @param {Object} user - The user object.
 * @param {Object} user.preference - The user's preferences to submit.
 * @returns {Promise} - A Promise that resolves with the server response.
 */
function submitUserPreferences(user) {
  const url = config.system.baseApiUrl + "/preferences";
  const { preference } = user;

  if (Array.isArray(preference)) {
    // We cannot submit a array as preference it must be a object.
    return false;
  }

  return axios.post(url, { preference })
    .then(response => {
      // handle success
      return response.data;
    })
    .catch(error => {
      // handle error
      console.error(error);
      return error;
    });
}

/**
 * Replaces the creation user if it matches any item in the list of users to replace.
 *
 * @param {string} creationUser - The original creation user value to be checked and possibly replaced.
 * @returns {string} The updated creation user value (either 'Automated Communication' or the original value).
 */
function replaceCreationUser(creationUser) {
  if (CRM_CREATION_USERS_TO_REPLACE.includes(creationUser.toLowerCase())) {
    // Replace with 'Automated Communication'
    return 'Automated Communication';
  }
  return creationUser;
}

/**
 * This function returns the string value for the propery assessment.due_in_term.
 *
 * @param {Number} val
 * @returns {String}
 */
function getDueInTermDesc(val) {
  if (val === 0) return 'Not Due In Term';
  if (val === 1) return 'Due In Term';
  if (val === 2) return 'No Due Date';
  return 'Unknown';
}

/**
 * get User's list of Courses and Units for header 'pins'
 *
 * @param {Object} user user object from home API with { courses, units, ...}
 * @param {Array} terms terms list from home API
 * @returns {String}
 */
function getUserCourseServices(user, terms) {
  const crsServices = user.courses.map(({ code, term_id }) => {
      const term = terms.find(y => y.id === term_id);
      const ENRfilter = isCurrent(term) && term.term !== 'VET' ? '&unitStatus=ENR' : '';
      const label = isCurrent(term) ? code : `${code} (${term.year} ${term.term})`;
      return {term, data: [label, `/cohort/${term.year}/${term.term}?${getParams([],[{value:code}])}${ENRfilter}`, faThumbtack]};
  });
  const untServices = user.units.map(({ code, term_id }) => {
      const term = terms.find(y => y.id === term_id);
      const ENRfilter = isCurrent(term) && term.term !== 'VET' ? '&unitStatus=ENR' : '';
      const label = isCurrent(term) ? code : `${code} (${term.year} ${term.term})`;
      return {term, data: [label, `/cohort/${term.year}/${term.term}?${getParams([{value:code}])}${ENRfilter}`, faThumbtack]};
  });

  // sort by term from newest to oldest
  const services = [...crsServices, ...untServices].sort(({term: a}, {term: b}) => {
      if (a.year > b.year) {
          return -1;
      }
      if (a.year < b.year) {
          return 1;
      }
      if (a.term > b.term) {
          return -1;
      }
      if (a.term < b.term) {
          return 1;
      }
      return 0;
  }).map(x => x.data);
  return services;
}

/**
 * get Student's selected enrollments
 *
 * @param {Array} students array of students
 * @param {Array} enrollments array of enrollments
 * @returns {Array} selected enrollments
 */
function getSelectedEnrollments(students, enrollments) {
  const studentIds = students.map((student) => student.id);
  return [...studentIds].reduce( (acc, id) => {
    const enrollment = enrollments.find(({ student_id }) => student_id === id);
    if (enrollment) {
      acc.set(enrollment.id, {
        studentId: enrollment.student.id,
        unitCode: enrollment.unit.code,
        studyPeriodCode: enrollment.study_period_code,
        year: enrollment.term.year,
      });
    }
    return acc;
  }, new Map());
}

/**
 * Converts an array of asset elements into an array of options with code and name as value and label.
 * Used in the dropdowns on the search/home page.
 *
 * @param {Array<Object>} elements - An array of elements containing 'code' and 'name' properties.
 * @returns {Array<Object>} An array of options with 'value' and 'label' properties.
 */
function convertAssetsToOptions(elements) {
  const elementMap = elements.reduce((acc, elem) => {
      acc.set(elem.code, { value: elem.code, label: `${elem.code}: ${elem.name}` });
      return acc;
  }, new Map());

  return Array.from(elementMap.values());
}

/**
 * Get the structure for submitting a enrollment for interaction.
 *
 * @param {*} enrollment
 * @returns
 */
const flattenEnrollment = (enrollment) => (
  {
    studentId: enrollment.student.id,
    unitCode: enrollment.unit.code,
    courseCode: enrollment.course.code,
    schoolCode: enrollment.school.code,
    studyPeriodCode: enrollment.study_period_code,
    year: enrollment.term.year,
  }
)

/**
 * Gets the modified by information for an announcement, the editor is prioritised oved author.
 *
 * @param {Object} author - The author of the announcement.
 * @param {Object} editor - The editor of the announcement.
 * @returns {string} Example: "edited by Scott Verbeek"
 */
function getAnnouncementModifiedBy(author, editor) {
  const modifier = editor || author;

  if (!modifier) {
    return '';
  }

  const name = [modifier.firstname, modifier.lastname].filter(Boolean).join(' ');
  const displayName = name ? name : modifier.username;

  const isEdited = editor === modifier;

  return `${isEdited ? 'edited' : 'authored'} by ${displayName}`;
}

/**
 * If the array is empty, add  (none).
 * This is so we can filter for the absence of things.
 *
 * @param {Array} arr
 * @returns {Array}  The original array, or ['(none')]
 */
function filterNone(arr, replacement) {
    if (arr.length === 0) {
        arr.push(replacement ? replacement : '(none)');
    }
    return arr;
}

/**
 * Finds elements hierarchical data structure that could be used as parent.
 *
 * @param {Array} data - The data array containing the hierarchical elements.
 * @param {number} elementId - The ID of the element to find valid parents for.
 * @param {string} [idKey='id'] - The key used to identify elements in the data array.
 * @param {string} [parentIdKey='parent_id'] - The key used to identify the parent of an element.
 * @returns {Array} An array of valid parent elements.
 */
function findValidParents(data, elementId, idKey = 'id', parentIdKey = 'parent_id') {
  // Find the element in the data array using the provided idKey
  const element = data.find(item => item[idKey] === elementId);

  // If the element is not found, return an empty array
  if (!element) {
    return [];
  }

  // Recursively find all descendants using the provided keys
  const descendants = findAllDescendants(data, elementId, idKey, parentIdKey);

  // Filter out the descendants from the overall data to get valid parents
  // Remove the current element from the valid parents list
  return data
    .filter(item => !descendants.includes(item[idKey]))
    .filter(item => item[idKey] !== elementId);
}

/**
 * Recursively finds all descendants of a given element in a hierarchical data structure.
 *
 * @param {Array} data - The data array containing the hierarchical elements.
 * @param {number} elementId - The ID of the element to find descendants for.
 * @param {string} [idKey='id'] - The key used to identify elements in the data array.
 * @param {string} [parentIdKey='parent_id'] - The key used to identify the parent of an element.
 * @returns {Array} An array of descendant element IDs.
 */
function findAllDescendants(data, elementId, idKey = 'id', parentIdKey = 'parent_id') {
  const descendants = [];

  // Base case: If the elementId is 0 (root), return an empty array
  if (elementId === 0) {
    return descendants;
  }

  // Find the element in the data array using the provided idKey
  const element = data.find(item => item[idKey] === elementId);

  // If the element is found, add its children to the descendants array and recursively find their descendants
  if (element) {
    descendants.push(element[idKey]);
    for (const childId of data.filter(item => item[parentIdKey] === elementId).map(item => item[idKey])) {
      descendants.push(...findAllDescendants(data, childId, idKey, parentIdKey));
    }
  }

  return descendants;
}

export {
  alphabet_divisions,
  alphabetical,
  bg_class,
  capitalize,
  cmp,
  divide,
  calc_percentage,
  getDueInTermDesc,
  calc_weighted_score,
  calc_unit_score,
  chunk_square,
  replaceCreationUser,
  color_scheme,
  flattenEnrollment,
  days_ago,
  getParams,
  deep_equal,
  tabId,
  sendLog,
  calcPassRate,
  submitUserPreferences,
  fg_class,
  filterNone,
  get_grade,
  getPreferenceFromUser,
  getTypeIcon,
  getAssessmentExtensionStateColor,
  get_success,
  get_Success,
  getFiltersFromParams,
  grammatical_join,
  haveSameData,
  isCurrentTerm,
  haveSameFilters,
  hide_string,
  getAnnouncementModifiedBy,
  is_function,
  isCurrent,
  make_object,
  message_crm_url,
  minutes,
  name_initials,
  pluralize,
  range,
  read_snooze_cookie,
  sort_units,
  sort_by_weight,
  filterByProperty,
  getValueByProperty,
  setValueByProperty,
  test_snooze_cookie,
  truncate,
  getPassRateGroup,
  getUserCourseServices,
  getSelectedEnrollments,
  convertAssetsToOptions,
  findValidParents,
  findAllDescendants,
};
